/* istanbul ignore else */
import React, { useState, useRef, useEffect } from 'react'
import { EditForkliftWrapper } from './styles'
import i18n from 'i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { labelName, tabConstants, dialogMessageList, requiredItems, dropdownKey, fileTypeList } from './valueMapping'
import { useForm, FormProvider } from 'react-hook-form'
import generalDefaultImg from '../../../assets/images/forklift.png'
import documentImg from '../../../assets/images/document-placeholder.svg'
import removeBtn from '../../../assets/images/remove-btn.png'
import Modal from '../../../components/Modal'
import { createForklift, deleteForklift, editForklift, getSingleForklift, setEditing } from '../../../features/forklift/forkliftSlice'
import { getFile, execFile, clearFileState } from '../../../features/file/fileSlice'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { getOrganizationList } from '../../../features/organizations/organizationSlice'
import { userInfo } from './../../../features/users/userInfoSlice'
import { OkButton, YesButton, NoButton, DeleteButton, SaveButton, BackButton } from '../../../components/Button'
import { CircularProgress } from '@material-ui/core'
import { dropDownContentListApi } from '../../../features/dropdown/dropdownAPI'
import RegisterMultiSelect from '../../../components/RegisterMultiSelect'
import { fileCategory, vehicleKind } from '../../../common/const'

function EditForklift (props) {
  const useFormMethods = useForm({ mode: 'onChange' })
  const { register, getValues, setValue, formState: { errors }, setError, clearErrors, watch, reset } = useFormMethods
  const location = useLocation()
  const history = useHistory()
  const { pathname } = location
  const splitLocation = props.state ? `/forklift/${props.state}`.split('/') : pathname.split('/')
  const pageFunction = splitLocation[splitLocation.length - 1]
  const headerTitle = pageFunction === 'create' ? i18n.t('FORKLIFT_FORKLIFT-CREATE_PAGE-HEADER_LABEL') : i18n.t('FORKLIFT_FORKLIFT-EDIT_PAGE-HEADER_LABEL')
  const [selectedFilesPhotos, setSelectedFilesPhotos] = useState([])
  const [selectedFilesDocumentModal1, setSelectedFilesDocumentModal1] = useState([])
  const [selectedFilesDocumentModal2, setSelectedFilesDocumentModal2] = useState([])
  const [selectedFilesDocument1, setSelectedFilesDocument1] = useState([])
  const [selectedFilesDocument2, setSelectedFilesDocument2] = useState([])
  const [selectedFilesDocument3, setSelectedFilesDocument3] = useState([])
  const [selectedFilesDocument4, setSelectedFilesDocument4] = useState([])
  const [selectedFilesDocument5, setSelectedFilesDocument5] = useState([])
  const [selectedFilesDocument6, setSelectedFilesDocument6] = useState([])
  const [selectedFilesDocument7, setSelectedFilesDocument7] = useState([])
  const [selectedFilesDocument8, setSelectedFilesDocument8] = useState([])
  const fileInput = useRef(null)
  const [showMessage, setShowMessage] = useState(false)
  const [showInspectionCertificate, setShowInspectionCertificate] = useState(false)
  const [showMandatoryInsurance, setShowMandatoryInsurance] = useState(false)
  const [showInspectionCertificateMessage, setShowInspectionCertificateMessage] = useState(false)
  const [showMandatoryInsuranceMessage, setShowMandatoryInsuranceMessage] = useState(false)
  const [dialogInfo, setDialogInfo] = useState({})
  const dispatch = useDispatch()
  const forkliftValue = useSelector(state => state.forklift.selectedForklift)
  const organizationState = useSelector(state => state.organization)
  const forkliftState = useSelector(state => state.forklift)
  const specialDropdown = ['company', 'department', 'businessUnit', 'branchOffice', 'branch']
  const [action, setAction] = useState('')
  const [requestBody, setRequestBody] = useState({})
  const [execParams, setExecParams] = useState({})
  const duplication = 'DUPLICATION'
  const hasCO2 = 'DEPENDENCY'
  const dropdownNotExist = 'DROPDOWN_NOT_EXIST'
  const integerNumberOnly = ['seatingCapacity', 'licensePlateNumber']
  const createAction = 'create'
  const editAction = 'edit'
  const noMoreErrors = 'none'
  const noMoreDeleteErrors = 'delete_none'
  const userState = useSelector(state => state.userInfo)
  const allowedChars = '0123456789.'
  const [errorFields, setErrorFields] = React.useState([])
  const fileState = useSelector(state => state.file)
  const [DropDownList, setDropDownList] = useState({})

  useEffect(() => {
    // Get master data
    dispatch(getOrganizationList())
    dispatch(userInfo())
    reset()

    // set editing
    dispatch(setEditing(true))
    dispatch(clearFileState())
    return () => {
      dispatch(setEditing(false))
    }
  }, [])

  useEffect(() => {
    setDropDownList({})
    // Get DropDown data
    Object.entries(dropdownKey).map(async (value) => {
      const response = await dropDownContentListApi({ categoryId: value[1] })
      // setDropDownListを更新
      setDropDownList(prevState => ({ ...prevState, [value[1]]: response.data.rows }))
    })
  }, [])

  useEffect(() => {
    if (pageFunction === editAction) {
      if (props.state) {
        dispatch(getSingleForklift({ id: props.id }))
        // 画像やPDFファイルをS3から取得
        Object.values(fileTypeList).map(async (value) => {
          dispatch(getFile({ id: props.id, category: value, vehicleKind: vehicleKind.forklift }))
        })
      } else {
        if (props.match.params) {
          dispatch(getSingleForklift({ id: props.match.params.id }))
          // 画像やPDFファイルをS3から取得
          Object.values(fileTypeList).map(async (value) => {
            dispatch(getFile({ id: props.match.params.id, category: value, vehicleKind: vehicleKind.forklift }))
          })
        }
      }
    }
  }, [])

  useEffect(async () => {
    const error = forkliftState.error
    const fileError = fileState.error
    // setIsLoading(isLoading)
    if (error && error !== noMoreErrors) {
      if (error[0] === duplication) showDialog('id_exist')
      else if (error[0] === hasCO2) showDialog('has_co2_report')
      else if (error[0] === dropdownNotExist) showDialog('dropdown_not_exist')
      else showDialog('unknown')
    }
    if (fileError && fileError !== noMoreErrors) {
      if (fileError[0] === duplication) showDialog('id_exist')
      else if (fileError[0] === hasCO2) showDialog('has_co2_report')
      else if (fileError[0] === dropdownNotExist) showDialog('dropdown_not_exist')
      else showDialog('unknown')
    }
    if (error && error === noMoreErrors && fileError && fileError === noMoreErrors) {
      if (action === editAction) {
        await dispatch(editForklift({ exec: true, id: props.id || props.match.params.id, body: requestBody }))
        props.onBack ? props.onBack('edit') : history.push(`/forkliftviewinfo/${props.match.params.id}`)
      } else if (action === createAction) {
        await dispatch(createForklift({ exec: true, body: requestBody }))
        props.onBack ? props.onBack('create') : history.push('/forklift')
      } else {
        props.onBack ? props.onBack('create') : history.push('/forklift')
      }
    }
    if (error && error === noMoreDeleteErrors) {
      props.onBack ? props.onBack('create') : history.push('/forklift')
    }
  }, [forkliftState.error, fileState.error])

  React.useEffect(() => {
    if (fileState.list.length !== 0) {
      // 車検証
      const syakenList = fileState.list.filter((x) => { return x.category === fileTypeList.Syaken })
      if (syakenList.length === 1) {
        if (syakenList[0].path !== null) {
          const path = syakenList[0].path
          selectedFilesDocument1.length === 0 && setSelectedFilesDocument1(prevState => [...prevState, path])
        } else {
          setSelectedFilesDocument1([])
        }
        setSelectedFilesDocument2([])
      } else if (syakenList.length === 2) {
        if (syakenList[0].path !== null) {
          const document1Path = syakenList[0].path
          selectedFilesDocument1.length === 0 && setSelectedFilesDocument1(prevState => [...prevState, document1Path])
        } else {
          setSelectedFilesDocument1([])
        }
        if (syakenList[1].path !== null) {
          const document2Path = syakenList[1].path
          selectedFilesDocument2.length === 0 && setSelectedFilesDocument2(prevState => [...prevState, document2Path])
        } else {
          setSelectedFilesDocument2([])
        }
      } else {
        setSelectedFilesDocument1([])
        setSelectedFilesDocument2([])
      }
      // 自賠責証書
      const zibaisekiList = fileState.list.filter((x) => { return x.category === fileTypeList.Zibaiseki })
      if (zibaisekiList.length === 1) {
        if (zibaisekiList[0].path !== null) {
          const path = zibaisekiList[0].path
          selectedFilesDocument3.length === 0 && setSelectedFilesDocument3(prevState => [...prevState, path])
        } else {
          setSelectedFilesDocument3([])
        }
        setSelectedFilesDocument5([])
      } else if (zibaisekiList.length === 2) {
        if (zibaisekiList[0].path !== null) {
          const document3Path = zibaisekiList[0].path
          selectedFilesDocument3.length === 0 && setSelectedFilesDocument3(prevState => [...prevState, document3Path])
        } else {
          setSelectedFilesDocument3([])
        }
        if (zibaisekiList[1].path !== null) {
          const document5Path = zibaisekiList[1].path
          selectedFilesDocument5.length === 0 && setSelectedFilesDocument5(prevState => [...prevState, document5Path])
        } else {
          setSelectedFilesDocument5([])
        }
      } else {
        setSelectedFilesDocument3([])
        setSelectedFilesDocument5([])
      }
      // 車両画像
      const syaryouiList = fileState.list.filter((x) => { return x.category === fileTypeList.Syaryou })
      if (syaryouiList.length >= 1) {
        const photoArray = []
        // 車検証や自賠責証書と異なり、右側が最新になるため逆順で表示
        for (let i = syaryouiList.length - 1; i >= 0; i--) {
          const path = syaryouiList[i].path
          if (path !== null) {
            photoArray.push(path)
          }
        }
        setSelectedFilesPhotos(prevState => { prevState = photoArray; return prevState })
      } else {
        setSelectedFilesPhotos([])
      }
      // 任意保険
      setFileDocument(fileTypeList.NiniHoken, selectedFilesDocument4, setSelectedFilesDocument4)
      // 年次点検報告書
      setFileDocument(fileTypeList.NenziTenkin, selectedFilesDocument6, setSelectedFilesDocument6)
      // 購入時契約書
      setFileDocument(fileTypeList.KounyuziKeiyaku, selectedFilesDocument7, setSelectedFilesDocument7)
      // レンタル時契約書
      setFileDocument(fileTypeList.Rental, selectedFilesDocument8, setSelectedFilesDocument8)
    } else {
      setSelectedFilesDocument1([])
      setSelectedFilesDocument2([])
      setSelectedFilesDocument3([])
      setSelectedFilesDocument4([])
      setSelectedFilesDocument5([])
      setSelectedFilesDocument6([])
      setSelectedFilesDocument7([])
      setSelectedFilesDocument8([])
      setSelectedFilesPhotos([])
    }
  }, [fileState.list])

  const setFileDocument = (category, document, setDocumet) => {
    const list = fileState.list.filter((x) => { return x.category === category })
    if (list.length === 1) {
      if (list[0].path != null) {
        const path = list[0].path
        document.length === 0 && setDocumet(prevState => [...prevState, path])
      } else {
        setDocumet([])
      }
    } else {
      setDocumet([])
    }
  }

  useEffect(async () => {
    if (pageFunction === editAction) {
      if (JSON.stringify(forkliftValue) !== JSON.stringify({})) {
        Object.keys(forkliftValue).map((e) => setValue(e, forkliftValue[e]));
        ('organization' in forkliftValue && forkliftValue.organization) && specialDropdown.forEach(item => {
          setValue(item, forkliftValue.organization[item])
        })
      }
    }
  }, [forkliftValue])

  const calculateNextMaintenance = () => {
    const lastMaintenance = new Date(getValues('lastRegularMaintenanceDate'))
    const newDate = new Date(lastMaintenance.getFullYear(), lastMaintenance.getMonth(), '01')
    // const nextMaintenance = new Date(newDate.setMonth(newDate.getMonth() + 3)) // .toISOString().substring(0, 10)
    const regularMaintenanceCycle = getValues('regularMaintenanceCycle') ? getValues('regularMaintenanceCycle').split('ヶ月') : []
    const cycleNum = regularMaintenanceCycle.length > 0 ? parseInt(regularMaintenanceCycle[0]) : 3
    // const gmCFormat = !isNaN(parseInt(cycleNum)) ? parseInt(cycleNum) : 3
    const nextMaintenance = new Date(newDate.setMonth(newDate.getMonth() + cycleNum))
    const options = { year: 'numeric', month: 'long' }
    const result = nextMaintenance.toLocaleDateString('ja-JP', options)
    setValue('nextRegularMaintenanceMonth', nextMaintenance.toISOString().substring(0, 10))
    return result
  }

  const contains = (stringValue, charValue) => {
    return stringValue.indexOf(charValue) > -1
  }
  const allowTypeNumericOnly = (item, e) => {
    let invalidKey = null
    if (!integerNumberOnly.includes(item)) {
      invalidKey = (e.key.length === 1 && !contains(allowedChars, e.key)) || (e.key === '.' && contains(e.target.value, '.'))
    } else {
      invalidKey = (e.key.length === 1 && !contains('0123456789', e.key))
    }
    return invalidKey && e.preventDefault()
  }

  const addZeroBeforeDot = (item) => {
    const value = getValues(item).toString()
    if (getValues(item).length === 1) {
      value.charAt(0) === '.' && setValue(item, `0${getValues(item)}0`)
    } else {
      value.charAt(0) === '.' && setValue(item, `0${getValues(item)}`)
    }
  }

  const renderInput = (element) => {
    let Options = []
    switch (element.type) {
      case 'select':
        if (organizationState.list.length > 0 && specialDropdown.includes(element.value)) {
          let options = []
          try {
            switch (element.value) {
              case 'department':
                options = getValues('company') ? Object.keys(organizationState.restructureData[getValues('company')]) : []
                break
              case 'businessUnit':
                options = getValues('company') &&
                  getValues('department')
                  ? Object.keys(organizationState.restructureData[getValues('company')][getValues('department')])
                  : []
                break
              case 'branchOffice':
                options = getValues('company') &&
                  getValues('department') &&
                  getValues('businessUnit')
                  ? Object.keys(organizationState.restructureData[getValues('company')][getValues('department')][getValues('businessUnit')])
                  : []
                break
              case 'branch':
                options = getValues('company') &&
                  getValues('department') &&
                  getValues('businessUnit') &&
                  getValues('branchOffice')
                  ? Object.keys(organizationState.restructureData[getValues('company')][getValues('department')][getValues('businessUnit')][getValues('branchOffice')])
                  : []
                break
              default:
                options = Object.keys(organizationState.restructureData)
                break
            }
          } catch (err) {}
          return (
            watch(specialDropdown) && <div className="input-group input-flex">
              <select className="input-item" {... element.required && { required: element.required }}
              {...register(element.value)}
              data-testid={element.value} value={getValues(element.value)}>
                <option value="">{i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}</option>
                {options.map(item => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
          )
        }
        if (Object.keys(DropDownList).length !== 0) {
          Object.entries(DropDownList).map(async (value) => {
            if (value[0] === element.options) {
              Options = value[1].map(item => {
                if (item.isNew === false || (item.isNew === true && item.id === forkliftValue[element.value])) {
                  return { key: item.id, value: item.name }
                }
                return null
              })
              if (element.required === false) {
                Options = [{ key: '', value: i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL') }, ...Options]
              }
            }
          })
        }
        Options = Options.filter(x => x)
        return (
          <div className="input-group input-flex">
            <RegisterMultiSelect
            className="input-item"
            marginLeft={'10px'}
            testId={element.value}
            placeholder={i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}
            options={Options}
            valueField="key"
            labelField="value"
            defaultValue={getValues(element.value)}
            element={element}
            onBlur={onBlur(element)}
            />
          </div>
        )
      case 'auto-calculated':
        return (<div className="input-group input-flex">
            <input
            type="text"
            {...register(element.value)}
            data-testid={element.value}
            hidden
            className="input-item"/>
            {watch('lastRegularMaintenanceDate') && <div className="next-maintenance">{calculateNextMaintenance()}</div>}
        </div>)
      case 'date': {
        const maxDate = new Date()
        maxDate.setDate(maxDate.getDate() + element.addMaxDate)
        return (<div className="input-group input-flex">
          <input
            type={ element.type }
            {...register(element.value)}
            {... element.required && { required: element.required }}
            data-testid={element.value}
            min="1990-01-01"
            max={maxDate.toLocaleDateString('sv-SE')}
            // onKeyDown={(e) => { (e.key !== 'Tab') && e.preventDefault() }}
            className="input-item"/>
        </div>)
      }
      case 'textarea':
        return (
          <div className="input-group input-flex" style={{ height: '50px' }}>
            <textarea
              type={ element.type }
              maxLength={element.maxLength}
              {...register(element.value)}
              data-testid={element.value}
              className="input-item">
            </textarea>
          </div>)
      case 'number':
        return (
          <div className="input-group input-flex">
            <input
              type="text"// { element.type }
              {...!integerNumberOnly.includes(element.value) && { step: 'any' }}
              onKeyDown={(e) => allowTypeNumericOnly(element.value, e)}
              maxLength={element.maxLength}
              {...register(element.value)}
              {... element.required && { required: element.required }}
              data-testid={element.value}
              className="input-item"/>
          </div>)
      case 'month':
        return (<div className="input-group input-flex">
          <input
            type={ element.type }
            {...register(element.value)}
            {... element.required && { required: element.required }}
            data-testid={element.value}
            min="1990-01"
            // 現状は1カラムしかないため固定値
            max="2100-12"
            // onKeyDown={(e) => { (e.key !== 'Tab') && e.preventDefault() }}
            className="input-item"/>
        </div>)
      default:
        if (element.value === 'licensePlateNumber') {
          const licensePlateItems = []
          element.subItems && element.subItems.forEach(item => {
            licensePlateItems.push(
              <input key={item.value}
                {...item.value === 'licensePlateNumber' && { onKeyDown: (e) => allowTypeNumericOnly(item.value, e) }}
                placeholder={ i18n.t(item.label) }
                type="text"
                maxLength={item.maxLength}
                {...register(item.value)}
                {... element.required && { required: element.required }}
                data-testid={item.value}
                className="input-item"/>
            )
          })
          return (
            <div className="input-group input-flex">
              {licensePlateItems}
            </div>)
        }
        return (
          <div className="input-group input-flex">
            <input
              type={ element.type }
              maxLength={element.maxLength}
              {...register(element.value)}
              {... element.required && { required: element.required }}
              data-testid={element.value}
              className="input-item"/>
          </div>)
    }
  }

  const renderErrorText = field => {
    let errorMessage = ''
    errorMessage = errors[field] && errors[field].message
    return errorMessage ? <p className="error-text" data-testid="error-text">{errorMessage}</p> : ''
  }

  const trimExcessSpace = (item) => {
    setValue(item.value, getValues(item.value).toString().trim())
  }

  const onBlur = item => () => {
    const { value, type } = item
    getValues(value) && trimExcessSpace(item)
    getValues(value) && addZeroBeforeDot(value)
    clearErrors(value)
    const index = errorFields.indexOf(`${value}`)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    if (item.required) {
      if (getValues(value) === '' || typeof getValues(value) === 'undefined') {
        setError(value, {
          type: 'manual',
          message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-MANDATORY_LABEL')
        })
        setErrorFields(prevState => [...prevState, `${value}`])
      }
    }
    if (type === 'number' && !/^[0-9.]*$/.test(getValues(value))) {
      setError(value, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-NUMERIC_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${value}`])
    }
    if (value === 'licensePlateNumber') {
      const licenseNum = item.subItems.find((e) => e.value === 'licensePlateNumber')
      if (licenseNum && !/^[0-9.]*$/.test(getValues(licenseNum.value))) {
        setError(value, {
          type: 'manual',
          message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-NUMERIC_LABEL')
        })
        setErrorFields(prevState => [...prevState, `${value}`])
      }
    }
    if (type === 'date' && getValues(value)) {
      const valueDate = new Date(getValues(value))
      const minDate = new Date('1990-01-01')
      const maxDate = new Date()
      maxDate.setDate(maxDate.getDate() + item.addMaxDate)
      if (minDate > valueDate || maxDate < valueDate) {
        setError(value, {
          type: 'manual',
          message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-DATE_LABEL')
        })
        setErrorFields(prevState => [...prevState, `${value}`])
      }
    }
    if (type === 'month' && getValues(value)) {
      const valueDate = new Date(getValues(value))
      const minDate = new Date('1990-01-01')
      const maxDate = new Date('2100-12-31')
      if (minDate > valueDate || maxDate < valueDate) {
        setError(value, {
          type: 'manual',
          message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-DATE_LABEL')
        })
        setErrorFields(prevState => [...prevState, `${value}`])
      }
    }
  }

  const renderInputArea = (areaName, maxItemEachCol) => {
    const data = labelName[areaName]
    // const result = []
    const left = []
    const right = []
    for (const i in data) {
      const element = data[i]
      const label = `${i18n.t(element.label)}${element.required ? '*' : ''}`
      if (i <= maxItemEachCol - 1) {
        left.push(
          <div key={i} className="input-container" onBlur={onBlur(element)}>
            <label className={ element.required ? 'label-required' : '' }>{label}</label>
            <div>
              {renderInput(element)}
              {renderErrorText(element.value)}
            </div>
          </div>
        )
      } else {
        right.push(
          <div key={i} className="input-container" onBlur={onBlur(element)}>
            <label className={ element.required ? 'label-required' : '' }>{label}</label>
            <div>
              {renderInput(element)}
              {renderErrorText(element.value)}
            </div>
          </div>
        )
      }
    }
    return (
      <div className={'split-2'}>
        <div className={'left'} >{left}</div>
        <div className={'right'} >{right}</div>
      </div>
    )
  }

  const showDialog = (messageCode, execPrms = {}) => {
    setShowMessage(true)
    if (messageCode === 'cancel') {
      setDialogInfo(dialogMessageList.find((e) => e.code === `${messageCode}_${pageFunction}_vehicle`))
    } else {
      messageCode === 'remove_file' && setExecParams(execPrms)
      setDialogInfo(dialogMessageList.find((e) => e.code === messageCode))
    }
  }

  const showInspectionCertificateDialog = () => {
    setShowInspectionCertificate(true)
    if (selectedFilesDocument1.length !== 0 && selectedFilesDocument2.length !== 0) {
      setShowInspectionCertificateMessage(true)
    } else {
      setShowInspectionCertificateMessage(false)
    }
  }

  const showMandatoryInsuranceDialog = () => {
    setShowMandatoryInsurance(true)
    if (selectedFilesDocument3.length !== 0 && selectedFilesDocument5.length !== 0) {
      setShowMandatoryInsuranceMessage(true)
    } else {
      setShowMandatoryInsuranceMessage(false)
    }
  }

  const handleImageChange = (files, setState, limit, tabDetail) => {
    files && files.map((file, index) => {
      return setState((prevImages) => {
        if (prevImages.length < limit) {
          return prevImages.concat([file])
        }
        return prevImages
      })
    })
    files && files.map(
      (file) => URL.revokeObjectURL(file) // avoid memory leak
    )
  }

  const handleDragOver = event => {
    event.preventDefault()
  }
  const handleOnDrop = (event, setState, tabDetail, fileTypes) => {
    // prevent the browser from opening the image
    const { limit, fileSize } = tabConstants[tabDetail]
    const sizeAsBytes = fileSize * 1024 * 1024
    event.preventDefault()
    event.stopPropagation()
    // let's grab the image file
    const files = Array.from(event.dataTransfer.files)
    const foundRightFileType = []
    let lengthOfDocs = 0
    if (tabDetail === 'registrationTab') {
      fileTypes.forEach((fileType) => {
        const foundRightFile = files.find((e) => e.type.includes(fileType))
        if (foundRightFile) foundRightFileType.push(foundRightFile)
      })
      if (foundRightFileType.length <= 0) {
        showDialog('wrong_file_type')
        return
      }
    } else {
      const foundWrongFile = files.find((e) => !fileTypes.includes(e.type))
      if (foundWrongFile) {
        showDialog('wrong_file_type')
        return
      }
    }

    switch (tabDetail) {
      case 'generalTab':
        lengthOfDocs = selectedFilesPhotos.length
        break
    }
    if (files.length > (limit - lengthOfDocs)) {
      showDialog('max_file_num')
      return
    }
    const foundExceedingFileSize = files.find((e) => e.size > sizeAsBytes)
    if (foundExceedingFileSize) {
      showDialog('max_file_size')
      return
    }
    handleImageChange(files, setState, limit, tabDetail)
  }

  const removeData = (params) => {
    const { num, list, oldNum, oldList } = params
    if (oldList !== null && oldList[oldNum] !== globalThis.undefined) {
      list.splice(num, 1, oldList[oldNum])
      oldList.splice(oldNum, 1)
    } else {
      list.splice(num, 1)
    }
  }

  const renderPhoto = (num, list, tab, label = null, oldNum = 0, oldList = null) => {
    const defaultImg = tab === 'generalTab' ? generalDefaultImg : documentImg
    let srcFile = list[num]
    let embedType = ''
    if (typeof srcFile === 'object') {
      embedType = srcFile.type
      srcFile = URL.createObjectURL(srcFile)
    }
    if (label) {
      if (embedType.includes('pdf')) {
        return (
          <>
            {list[num] && <img src={removeBtn} data-testid="remove-btn" className="remove-btn" onClick={() => showDialog('remove_file', { num, list, tab, oldNum, oldList })} />}
            {list[num] && <embed src={srcFile} style={{ pointerEvents: 'none' }}/> }
            {label && <p>{i18n.t(label)}</p>}
          </>
        )
      }
      if (embedType.includes('image')) {
        return (
          <>
            {list[num] && <img src={removeBtn} data-testid="remove-btn" className="remove-btn" onClick={() => showDialog('remove_file', { num, list, tab, oldNum, oldList })} />}
            {list[num] && <img src={srcFile} alt="" /> }
            {label && <p>{i18n.t(label)}</p>}
          </>
        )
      }
      return (
        <>
          {list[num] && <img src={removeBtn} data-testid="remove-btn" className="remove-btn" onClick={() => showDialog('remove_file', { num, list, tab, oldNum, oldList })} />}
          {list[num] ? <embed src={srcFile} style={{ pointerEvents: 'none' }}/> : <img src={defaultImg} alt="" style={{ maxWidth: '60px', filter: 'brightness(0) saturate(100%) invert(29%) sepia(27%) saturate(474%) hue-rotate(132deg) brightness(92%) contrast(98%)' }} /> }
          {label && <p>{i18n.t(label)}</p>}
        </>
      )
    }
    return (
    <>
      {list[num] && <img src={removeBtn} data-testid="remove-btn" className="remove-btn" onClick={() => showDialog('remove_file', { num, list, tab, oldNum, oldList })} />}
      {list[num] ? <img src={srcFile} alt="" /> : <img className={`${tab === 'generalTab' && 'with-border'}`} src={defaultImg} alt="" style={{ maxWidth: '60px', filter: 'brightness(0) saturate(100%) invert(29%) sepia(27%) saturate(474%) hue-rotate(132deg) brightness(92%) contrast(98%)' }} /> }
    </>
    )
  }

  const validation = (requiredItems) => {
    let countErrors = 0
    requiredItems.forEach(element => {
      if (getValues(element) === '' || typeof getValues(element) === 'undefined') {
        setError(element, {
          type: 'manual',
          message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-MANDATORY_LABEL')
        })
        setErrorFields(prevState => [...prevState, `${element}`])
        countErrors += 1
      }
    })
    if (countErrors > 0) {
      return false
    } else {
      return true
    }
  }

  const onClickConfirm = async (dlgInfo) => {
    switch (dlgInfo.code) {
      case 'cancel_create_vehicle':
        props.onBack ? props.onBack('create') : history.push('/forklift')
        break
      case 'cancel_edit_vehicle':
        props.onBack ? props.onBack('edit') : history.push(`/forkliftviewinfo/${props.match.params.id}`)
        break
      case 'remove_file':
        removeData(execParams)
        break
      case 'delete_vehicle':
        confirmDelete()
        break
      default: // 'save'
        // triggerSubmit.current.click()
        onSubmit()
        break
    }
    setShowMessage(false)
  }

  const confirmDelete = async () => {
    setAction('delete')
    await dispatch(deleteForklift({ id: props.id || props.match.params.id }))
  }

  const removeEmpty = (obj) => {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null && v !== '' && v !== undefined)
        .map(([k, v]) => [k, v !== Object(v) && v])
        // .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
    )
  }

  const onClickCancel = (dlgInfo) => {
    setShowMessage(false)
  }

  const onSubmit = async (data) => {
    if (validation(requiredItems)) {
      const newData = prepareRequestBody()
      let body = removeEmpty(newData)
      delete body.id
      body = await prepareFileInfoBody(body)
      if (pageFunction === createAction) {
        setRequestBody(body)
        pageFunction && setAction(pageFunction)
        await dispatch(createForklift({ exec: false, body }))
        await dispatch(execFile({ exec: false, id: null, vehicleKind: vehicleKind.forklift, body }))
      } else {
        Object.keys(forkliftValue).map((key) => {
          if (forkliftValue[key] !== '' && getValues(key) === '') {
            body[key] = null
          }
          return key
        })
        setRequestBody(body)
        pageFunction && setAction(pageFunction)
        await dispatch(editForklift({ exec: false, id: props.id || props.match.params.id, body }))
        await dispatch(execFile({ exec: false, id: props.id || props.match.params.id, body }))
      }
    }
  }

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    // reader.onerror = error => reject(error)
  })

  const prepareRequestBody = () => {
    const findOrganization = organizationState.list && organizationState.list.find((e) => e.company === getValues('company') &&
      e.department === getValues('department') &&
      e.businessUnit === getValues('businessUnit') &&
      e.branchOffice === getValues('branchOffice') &&
      e.branch === getValues('branch'))
    setValue('organizationId', findOrganization && findOrganization.id)
    return getValues()
  }

  const prepareFileInfoBody = async (dataBody) => {
    const reqAttachments = {
      document2: selectedFilesDocument2[0] || null,
      document1: selectedFilesDocument1[0] || null,
      document5: selectedFilesDocument5[0] || null,
      document3: selectedFilesDocument3[0] || null,
      document4: selectedFilesDocument4[0] || null,
      document6: selectedFilesDocument6[0] || null,
      document7: selectedFilesDocument7[0] || null,
      document8: selectedFilesDocument8[0] || null,
      FilesPhotos: selectedFilesPhotos || null
    }
    const data = []
    await Promise.all(Object.keys(reqAttachments).map(async (key) => {
      let category = ''
      switch (key) {
        case 'document1':
        case 'document2':
          category = fileCategory.Syaken
          break
        case 'document3':
        case 'document5':
          category = fileCategory.Zibaiseki
          break
        case 'document4':
          category = fileCategory.NiniHoken
          break
        case 'document6':
          category = fileCategory.NenziTenkin
          break
        case 'document7':
          category = fileCategory.KounyuziKeiyaku
          break
        case 'document8':
          category = fileCategory.Rental
          break
        case 'FilesPhotos':
          category = fileCategory.Syaryou
          break
      }
      if (reqAttachments[key] !== null) {
        if (key === 'FilesPhotos') {
          for (let i = 0; i < reqAttachments[key].length; i++) {
            const fileData = await getInsertFileInfo(key, reqAttachments[key][i], category)
            if (fileData !== null) {
              data.push(fileData)
            }
          }
        } else {
          const fileData = await getInsertFileInfo(key, reqAttachments[key], category)
          if (fileData !== null) {
            data.push(fileData)
          }
        }
      }
    }))
    for (let i = 0; i < fileState.list.length; i++) {
      const fileData = await getDeleteFileInfo(fileState.list[i], reqAttachments)
      if (fileData !== null) {
        data.push(fileData)
      }
    }
    dataBody.fileList = data
    return dataBody
  }

  const getInsertFileInfo = async (key, file, category) => {
    const document = fileState.list.filter((x) => { return x.path === file })
    if (document.length === 0) {
      return {
        id: null,
        path: await getb64File(file, key),
        category: category,
        fileType: file.type.split('/')[1],
        execFlg: 'insert'
      }
    }
    return null
  }

  const getDeleteFileInfo = async (fileInfo, reqAttachments) => {
    let existFlg = false
    await Promise.all(Object.keys(reqAttachments).map(async (key) => {
      let path = reqAttachments[key]
      if (key === 'FilesPhotos') {
        const document = reqAttachments[key].filter((x) => { return x === fileInfo.path })
        if (document.length >= 1) {
          path = document[0]
        }
      }
      if (path === fileInfo.path) {
        existFlg = true
      }
    }))
    if (existFlg === false) {
      const splitPath = fileInfo.path.split('/')
      return {
        id: fileInfo.id,
        path: splitPath[splitPath.length - 1],
        category: fileInfo.category,
        fileType: 'pdf',
        execFlg: 'delete'
      }
    } else {
      return null
    }
  }

  const getb64File = async (fileData, key) => {
    if (fileData) {
      // Get image data if the data come from backend
      if (typeof fileData === 'string') {
        const response = await fetch(fileData, {
          credentials: 'include'
        })
        const blob = await response.blob()
        fileData = new File([blob], key, { type: blob.type })
      }
      // Add to the request
      const b64File = await toBase64(fileData)
      return b64File
    } else {
      return null
    }
  }

  const setInspectionCertificateFile = (okFlg) => {
    if (okFlg === true && selectedFilesDocumentModal1.length !== 0) {
      // 現行にファイルが設定されている場合、前回に移動
      if (selectedFilesDocument1.length !== 0) {
        setSelectedFilesDocument2(selectedFilesDocument1)
      }
      // 現行にダイアログで設定されたPDFファイルを設定
      setSelectedFilesDocument1(selectedFilesDocumentModal1)
    }
    // ダイアログのPDFを削除
    setSelectedFilesDocumentModal1([])
    // ダイアログを閉じる
    setShowInspectionCertificate(false)
  }

  const setMandatoryInsuranceFile = (okFlg) => {
    if (okFlg === true && selectedFilesDocumentModal2.length !== 0) {
      // 現行にファイルが設定されている場合、前回に移動
      if (selectedFilesDocument3.length !== 0) {
        setSelectedFilesDocument5(selectedFilesDocument3)
      }
      // 現行にダイアログで設定されたPDFファイルを設定
      setSelectedFilesDocument3(selectedFilesDocumentModal2)
    }
    // ダイアログのPDFを削除
    setSelectedFilesDocumentModal2([])
    // ダイアログを閉じる
    setShowMandatoryInsurance(false)
  }

  return (
    <EditForkliftWrapper>
      <Modal show={forkliftState.isLoading}><CircularProgress></CircularProgress></Modal>
      <Modal show={showInspectionCertificate}>
        <div className="modal-body">
          <div className="modal-message-content">
            {
              <>
                <p className="modal-header-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_INSPECTION-CERTIFICATE-UPLOAD_LABEL')}</p>
                <div className="drop_zone modal-dropzone" data-testid="drop-doc-1"
                  onDragOver = {handleDragOver}
                  onDrop = {e => handleOnDrop(e, setSelectedFilesDocumentModal1, 'registrationTab', ['application/pdf'])}
                >
                    <input type="file" multiple accept='application/pdf' hidden/>
                    <div className="image">
                      <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocumentModal1, 'registrationTab', 'VEHICLE_VEHICLE-CREATE-EDIT_MODAL_LABEL')}</div>
                    </div>
                </div>
                <p className="modal-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_MODAL_MASSAGE_LABEL')}</p>
                {showInspectionCertificateMessage === true && <p className="modal-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_MODAL_INSPECTION-CERTIFICATE-FILE-CONFIRM-MASSAGE_LABEL')}</p>}
              </>
            }
          </div>
          {<div className="modal-message-button">
              <YesButton data-testid="message-yes" onClick={() => setInspectionCertificateFile(true)} />
              <NoButton data-testid="message-no" onClick={() => setInspectionCertificateFile(false)} />
            </div>}
        </div>
      </Modal>
      <Modal show={showMandatoryInsurance}>
        <div className="modal-body">
          <div className="modal-message-content">
            {
              <>
                <p className="modal-header-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_MANDATORY-INSURANCE-UPLOAD_LABEL')}</p>
                  <div className="drop_zone modal-dropzone" data-testid="drop-doc-1"
                    onDragOver = {handleDragOver}
                    onDrop = {e => handleOnDrop(e, setSelectedFilesDocumentModal2, 'registrationTab', ['application/pdf'])}
                  >
                      <input type="file" multiple accept='application/pdf' hidden/>
                      <div className="image">
                        <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocumentModal2, 'registrationTab', 'VEHICLE_VEHICLE-CREATE-EDIT_MODAL_LABEL')}</div>
                      </div>
                  </div>
                <p className="modal-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_MODAL_MASSAGE_LABEL')}</p>
                {showMandatoryInsuranceMessage === true && <p className="modal-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_MODAL_MANDATORY-INSURANCE-FILE-CONFIRM-MASSAGE_LABEL')}</p>}
              </>
            }
          </div>
          {<div className="modal-message-button">
              <YesButton data-testid="message-yes" onClick={() => setMandatoryInsuranceFile(true)} />
              <NoButton data-testid="message-no" onClick={() => setMandatoryInsuranceFile(false)} />
            </div>}
        </div>
      </Modal>
      <Modal show={showMessage}>
        <div className="modal-body">
          <div className="modal-message-content">{i18n.t(dialogInfo.message)}</div>
          {dialogInfo.type === 'error'
            ? <div className="modal-message-button">
              <OkButton data-testid="message-ok" onClick={() => setShowMessage(false)} />
            </div>
            : <div className="modal-message-button">
              <YesButton data-testid="message-yes" onClick={() => onClickConfirm(dialogInfo)} />
              <NoButton data-testid="message-no" onClick={() => onClickCancel(dialogInfo)} />
            </div>}
        </div>
      </Modal>
      <section className="header fix-header">
        <div className="header-title">{headerTitle}</div>
        <div className="header-actions">
          {
            (userState && ((userState.isAdmin && userState.isEdit) || userState.isEdit))
              ? pageFunction === editAction && <DeleteButton data-testid="btn-delete" onClick={() => showDialog('delete_vehicle')}/>
              : ''
          }
          <SaveButton data-testid="btn-save" onClick={() => showDialog('save')} {...errorFields.length > 0 && { disabled: true }}/>
          <BackButton data-testid="btn-cancel" onClick={() => showDialog('cancel')}/>
        </div>
      </section>
      <FormProvider {...useFormMethods}>
        <form className="form-container" noValidate>
          <section className="tab" >
            <div className="tab-container">
              <h4>{i18n.t(tabConstants.generalTab.title)}</h4>
              {renderInputArea('generalTab', tabConstants.generalTab.maxItemEachCol)}
              <div className="wrapper">
                <div className="drop_zone general-dropzone" data-testid="drop-photo"
                  onDragOver = {handleDragOver}
                  onDrop = {e => handleOnDrop(e, setSelectedFilesPhotos, 'generalTab', ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'])}
                >
                    <input type="file" multiple accept="image/gif, image/jpeg, image/png, image/jpg" ref={fileInput} hidden/>
                    <div className="image">
                    { [...Array(tabConstants.generalTab.limit).keys()].map((e, i) => {
                      return <div key={`generalTab${i}`} className="img-item">{renderPhoto(e, selectedFilesPhotos, 'generalTab')}</div>
                    })}
                    </div>
                    <p className="dragdrop-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_VEHICLE-PHOTO-DESC_LABEL')}</p>
                </div>
              </div>
            </div>
          </section>

          <section className="tab" >
            <div className="tab-container">
              <h4>{i18n.t(tabConstants.registrationTab.title)}</h4>
              {renderInputArea('registrationTab', tabConstants.registrationTab.maxItemEachCol)}
              <div className="wrapper" style={{ flexDirection: 'column' }}>
                <div className="registration-wrapper1">
                  <div className="drop_zone registration-dropzone" data-testid="drop-doc-6"
                    onDragOver = {handleDragOver}
                    onDrop = {e => handleOnDrop(e, setSelectedFilesDocument6, 'registrationTab', ['application/pdf'])}
                  >
                      <input type="file" multiple accept='image/gif, image/jpeg, image/png, image/jpg, application/pdf' hidden/>
                      <div className="image">
                        <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocument6, 'registrationTab', 'FORKLIFT_FORKLIFT-CREATE-EDIT_ANNUAL-INSPECTION-REPORT_LABEL')}</div>
                      </div>
                  </div>
                  <div className="drop_zone registration-dropzone" data-testid="drop-doc-7"
                    onDragOver = {handleDragOver}
                    onDrop = {e => handleOnDrop(e, setSelectedFilesDocument7, 'registrationTab', ['application/pdf'])}
                  >
                      <input type="file" multiple accept='image/gif, image/jpeg, image/png, image/jpg, application/pdf' hidden/>
                      <div className="image">
                        <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocument7, 'registrationTab', 'FORKLIFT_FORKLIFT-CREATE-EDIT_PURCHASE-AGREEMENT_LABEL')}</div>
                      </div>
                  </div>
                  <div className="drop_zone registration-dropzone" data-testid="drop-doc-8"
                    onDragOver = {handleDragOver}
                    onDrop = {e => handleOnDrop(e, setSelectedFilesDocument8, 'registrationTab', ['application/pdf'])}
                  >
                      <input type="file" multiple accept='image/gif, image/jpeg, image/png, image/jpg, application/pdf' hidden/>
                      <div className="image">
                        <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocument8, 'registrationTab', 'FORKLIFT_FORKLIFT-CREATE-EDIT_RENTAL-CONTRACT_LABEL')}</div>
                      </div>
                  </div>
                </div>
                <div className="button-wrapper" >
                  <div className="inspection-certificate-button-wrapper">
                  <button type='button' onClick={() => showInspectionCertificateDialog()}>{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_INSPECTION-CERTIFICATE-UPLOAD_LABEL')}</button>
                  </div>
                  <div className="mandatory-insurance-button-wrapper">
                    <button type='button' onClick={() => showMandatoryInsuranceDialog()}>{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_MANDATORY-INSURANCE-UPLOAD_LABEL')}</button>
                  </div>
                </div>
                <div className="registration-wrapper">
                  <div className="drop_zone registration-dropzone" data-testid="drop-doc-1"
                    onDragOver = {handleDragOver}
                  >
                      <div className="image">
                        <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocument1, 'registrationTab', 'VEHICLE_VEHICLE-CREATE-EDIT_INSPECTION-CERTIFICATE-PREVIOUS_LABEL', 0, selectedFilesDocument2)}</div>
                      </div>
                  </div>
                  <div className="drop_zone registration-dropzone" data-testid="drop-doc-2"
                    onDragOver = {handleDragOver}
                  >
                      <div className="image">
                        <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocument2, 'registrationTab', 'VEHICLE_VEHICLE-CREATE-EDIT_INSPECTION-CERTIFICATE-TWO-TIMES-BEFORE_LABEL')}</div>
                      </div>
                  </div>
                  <div className="drop_zone registration-dropzone" data-testid="drop-doc-3"
                    onDragOver = {handleDragOver}
                  >
                      <div className="image">
                        <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocument3, 'registrationTab', 'VEHICLE_VEHICLE-CREATE-EDIT_MANDATORY-INSURANCE-POLICY_LABEL', 0, selectedFilesDocument5)}</div>
                      </div>
                  </div>
                  <div className="drop_zone registration-dropzone" data-testid="drop-doc-5"
                    onDragOver = {handleDragOver}
                  >
                      <div className="image">
                        <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocument5, 'registrationTab', 'VEHICLE_VEHICLE-CREATE-EDIT_INSPECTION-CERTIFICATE-LAST-TIME_LABEL')}</div>
                      </div>
                  </div>
                  <div className="drop_zone registration-dropzone" data-testid="drop-doc-4"
                    onDragOver = {handleDragOver}
                    onDrop = {e => handleOnDrop(e, setSelectedFilesDocument4, 'registrationTab', ['application/pdf'])}
                  >
                      <input type="file" multiple accept='image/gif, image/jpeg, image/png, image/jpg, application/pdf' hidden/>
                      <div className="image">
                        <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocument4, 'registrationTab', 'FORKLIFT_FORKLIFT-CREATE-EDIT_VOLUNTARY-INSURANCE-REPORT_LABEL')}</div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tab" >
            <div className="tab-container">
              <h4>{i18n.t(tabConstants.weightSizeInfoTab.title)}</h4>
              {renderInputArea('weightSizeInfoTab', tabConstants.weightSizeInfoTab.maxItemEachCol)}
            </div>
          </section>
          <section className="tab remove-margin-bottom">
            <div className="tab-container">
              <h4>{i18n.t(tabConstants.detailedVehicleInfo.title)}</h4>
              {renderInputArea('detailedVehicleInfoTab', tabConstants.detailedVehicleInfo.maxItemEachCol)}
              <div className="input-remarks" onBlur={onBlur({ value: 'importantNotes', type: 'text' })}>
                <label>{i18n.t('FORKLIFT_COMMON_IMPORTANTNOTES_LABEL')}</label>
                <div>
                  <div className="input-group input-flex">
                    <input
                      type={ 'text' }
                      maxLength={'255'}
                      {...register('importantNotes')}
                      data-testid={'importantNotes'}
                      className="input-item"/>
                  </div>
                  {renderErrorText('importantNotes')}
              </div>
            </div>
            </div>
          </section>
        </form>
      </FormProvider>
    </EditForkliftWrapper>)
}

export default EditForklift

EditForklift.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  state: PropTypes.string,
  id: PropTypes.string,
  onBack: PropTypes.func
}
