import styled from 'styled-components'
export const EditForkliftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .form-container {
    overflow: auto;
    height: calc(100vh - 66px);
    padding: 0 12px;
  }
  section.header {
    padding: 12px 12px 0px 12px;
    display: flex;
    height 50px;
    background: white;
    z-index: 1;
    .header-title {
      align-self: auto;
      font-size: 24px;
    }
    .header-actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: normal;
      gap: 10px;
    }
  }
  section.tab {
    box-sizing: border-box;
    border: solid 1px #ccc;
    border-radius: 5px;
    margin-bottom: 24px;
  }
  section.remove-margin-bottom {
    margin-bottom: 12px;
  }
  .tab-container{
    padding: 12px;
    h4 {
      font-size: 16px;
    }
  }
  .input-area{
    display:flex;
  }
  div.input-container {
    display: grid;
    min-width: 0;
    margin: 10px;
    grid-template: auto / 40% 60%;
    label {
      font-size: 14px;
    }
    label.label-required {
      color: red;
    }
  }
  div.input-group {
    display: flex;
    flex: 0.5;
    height: 36px;
    textarea {
      resize: none;
    }
    input[type=date], input[type=month] {
      ::-webkit-calendar-picker-indicator {
        padding: 2px;
        margin: auto;
      }
    }
  }
  div.input-remarks {
    display: grid;
    min-width: 0;
    margin: 0 10px 10px 10px;
    grid-template: auto / 19.6% 80.4%;
    label {
      font-size: 14px;
    }
  }
  select.input-item, input.input-item, textarea {
    flex: 1 1 auto;
    width: 0;
    margin-left: 10px;
    border: none;
    border:solid 1px #757575;
    border-radius: 5px;
    font-size: 14px;
  }
  label {
    align-self: center;
  }
  .split-2 {
    display: flex;
    gap: 15px;
    .left { flex: 1; }
    .right { flex: 1; }
  }
  .split-3{
    display: flex;
    .left { flex: 1; }
    .middle { flex: 1; }
    .right { flex: 1; }
  }
  .wrapper {
    padding:20px;
    text-align:-webkit-center;
  }
  .button-wrapper {
    display: flex;
    justify-content: left;
    padding-bottom:10px;
  }
  .inspection-certificate-button-wrapper {
  }
  .mandatory-insurance-button-wrapper {
    padding-left: 30.5%;
  }
  .registration-wrapper {
    display: flex;
    justify-content: space-evenly;
  }
  .registration-wrapper1 {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 10px;
  }
  .drop_zone {
      display: flex;
      flex-direction: column;
      background: white;
      background-image: url("/icons/upload.png");
      background-repeat: no-repeat;
      background-size: 100px;
      background-position: center;
      background-color: #ffffff;
      cursor: pointer;
      border: 2px dashed darkslategray;
      border-radius: 6px;
      // margin-bottom: 25px;
      background-position: center 28px;
  }
  .general-dropzone {
    height: 160px;
    width: 99.8%;
    padding-bottom: 10px;
  }
  .registration-dropzone {
    flex: 0.2;
  }
  .dragdrop-message {
    margin: 10px;
    font-size: 15px;
    color: darkslategray;
  }
  .image{
    min-height: 60%;
    width: 100%;
    margin:1rem 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }
  .img-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-inline-size: fit-content;
    height: 70px;
    flex: 0.15;
    object-fit: cover;
    position: relative;
    margin-left: 10px;
    .remove-btn {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 15px;
    }
    img, embed{
      max-width: 75%;
      max-height: 100%;
      object-fit: cover;
    }
    p{
      font-size:14px;
      width: 135px;
      margin: 12px 0 0 0;
      color: darkslategray;
    }
  }
  p.error-text {
    margin: 0 0 0 10px;
    font-size: 12px;
    color: red;
  }
  .img-border{
    border: solid 1px darkslategray;
    height: 95px;
  }
  h4{margin:0;}
  .next-maintenance{
    margin-left: 10px;
    font-size: small;
  }

  .modal-close-button {
    position: absolute;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    right: -6px;
    top: -6px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }

  .modal-box {
    position: relative;
    width: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    background: ${props => props.theme.white};
  }
  .modal-message-content {
    min-width: 300px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .modal-message-button {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 30px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  img.with-border {
    border: solid 1px darkslategray;
    border-radius: 5px;
    padding: 10px;
  }

  .modal-message {
    margin: 10px;
    font-size: 15px;
    text-align: center;
  }

  .modal-header-message {
    margin: 10px;
    font-size: 20px;
    text-align: center;
  }

  .modal-dropzone {
    width: 50%;
    margin:  0 auto; 
  }
`
