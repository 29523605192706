/* istanbul ignore else */
import React, { useState, useRef, useEffect } from 'react'
import { EditVehicleWrapper } from './styles'
import i18n from 'i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { labelName, tabConstants, dialogMessageList, dropdownKey, requiredItems, businessUseItems } from './valueMapping'
import { useForm, FormProvider } from 'react-hook-form'
import generalDefaultImg from '../../../assets/images/truck-placeholder.png'
import documentImg from '../../../assets/images/document-placeholder.svg'
import removeBtn from '../../../assets/images/remove-btn.png'
import Modal from '../../../components/Modal'
import { createVehicle, deleteVehicle, editVehicle, getSingleVehicle, setEditing } from '../../../features/vehicles/vehicleSlice'
import { getFile, execFile, clearFileState } from '../../../features/file/fileSlice'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
// import { getAttachFileApi } from '../../../features/vehicles/vehicleAPI'
import { getOrganizationList } from '../../../features/organizations/organizationSlice'
import { userInfo } from './../../../features/users/userInfoSlice'
import { OkButton, YesButton, NoButton, DeleteButton, SaveButton, BackButton } from '../../../components/Button'
import { CircularProgress } from '@material-ui/core'
import { fileCategory, vehicleKind } from '../../../common/const'
import { dropDownContentListApi } from '../../../features/dropdown/dropdownAPI'
import RegisterMultiSelect from '../../../components/RegisterMultiSelect'

function EditVehicle (props) {
  const useFormMethods = useForm({ mode: 'onChange' })
  const { register, getValues, setValue, formState: { errors }, setError, clearErrors, watch, reset } = useFormMethods
  const location = useLocation()
  const history = useHistory()
  const { pathname } = location
  const splitLocation = props.state ? `/vehicle/${props.state}`.split('/') : pathname.split('/')
  const pageFunction = splitLocation[splitLocation.length - 1]
  const headerTitle = pageFunction === 'create' ? i18n.t('VEHICLE_VEHICLE-CREATE_PAGE-HEADER_LABEL') : i18n.t('VEHICLE_VEHICLE-EDIT_PAGE-HEADER_LABEL')
  const [selectedFilesPhotos, setSelectedFilesPhotos] = useState([])
  const [selectedFilesDocumentModal1, setSelectedFilesDocumentModal1] = useState([])
  const [selectedFilesDocumentModal2, setSelectedFilesDocumentModal2] = useState([])
  const [selectedFilesDocument1, setSelectedFilesDocument1] = useState([])
  const [selectedFilesDocument2, setSelectedFilesDocument2] = useState([])
  const [selectedFilesDocument3, setSelectedFilesDocument3] = useState([])
  const [selectedFilesDocument4, setSelectedFilesDocument4] = useState([])
  const [selectedFilesDocument5, setSelectedFilesDocument5] = useState([])
  const [selectedFilesBlueprints, setSelectedFilesBlueprints] = useState([])
  const fileInput = useRef(null)
  const fileInputWS = useRef(null)
  // const triggerSubmit = useRef(null)
  const [showMessage, setShowMessage] = useState(false)
  const [showInspectionCertificate, setShowInspectionCertificate] = useState(false)
  const [showMandatoryInsurance, setShowMandatoryInsurance] = useState(false)
  const [showInspectionCertificateMessage, setShowInspectionCertificateMessage] = useState(false)
  const [showMandatoryInsuranceMessage, setShowMandatoryInsuranceMessage] = useState(false)
  const [dialogInfo, setDialogInfo] = useState({})
  const dispatch = useDispatch()
  const vehicleValue = useSelector(state => state.vehicle.selectedVehicle)
  const organizationState = useSelector(state => state.organization)
  const vehicleState = useSelector(state => state.vehicle)
  const specialDropdown = ['company', 'department', 'businessUnit', 'branchOffice', 'branch']
  const [action, setAction] = useState('')
  const [requestBody, setRequestBody] = useState({})
  const [execParams, setExecParams] = useState({})
  const duplication = 'DUPLICATION'
  const hasCO2 = 'DEPENDENCY'
  const dropdownNotExist = 'DROPDOWN_NOT_EXIST'
  const integerNumberOnly = ['seatingCapacity', 'licensePlateNumber']
  const createAction = 'create'
  const editAction = 'edit'
  const noMoreErrors = 'none'
  const noMoreDeleteErrors = 'delete_none'
  const userState = useSelector(state => state.userInfo)
  const allowedChars = '0123456789.'
  const [errorFields, setErrorFields] = React.useState([])
  const [contentType, setContentType] = React.useState('application/pdf')
  const fileState = useSelector(state => state.file)
  const [DropDownList, setDropDownList] = useState({})
  const [localLabelName, setLocalLabelName] = useState([])
  const [localRequiredItems, setLocalRequiredItems] = useState([])
  const [drodownFlg, setdrodownFlg] = useState(false)
  const [dataFlg, setdataFlg] = useState(false)
  // const [isLoading, setIsLoading] = React.useState(false)

  useEffect(() => {
    // Get master data
    dispatch(getOrganizationList())
    dispatch(userInfo())
    reset()
    // Object.keys(getValues()).map((key) => setValue(key, ''))
    // 必須項目の初期表示時変更
    const labelNameCopy = JSON.parse(JSON.stringify(labelName))
    setLocalLabelName(labelNameCopy)
    const requiredItemsCopy = JSON.parse(JSON.stringify(requiredItems))
    setLocalRequiredItems(requiredItemsCopy)

    // set editing
    dispatch(setEditing(true))
    dispatch(clearFileState())
    return () => {
      dispatch(setEditing(false))
    }
  }, [])

  useEffect(() => {
    if (pageFunction === editAction) {
      if (props.state) {
        dispatch(getSingleVehicle({ id: props.id }))
        // 車検証を取得
        dispatch(getFile({ id: props.id, category: fileCategory.Syaken, vehicleKind: vehicleKind.vehicle }))
        // 自賠責証書を取得
        dispatch(getFile({ id: props.id, category: fileCategory.Zibaiseki, vehicleKind: vehicleKind.vehicle }))
      } else {
        if (props.match.params) {
          dispatch(getSingleVehicle({ id: props.match.params.id }))
          // 車検証を取得
          dispatch(getFile({ id: props.match.params.id, category: fileCategory.Syaken, vehicleKind: vehicleKind.vehicle }))
          // 自賠責証書を取得
          dispatch(getFile({ id: props.match.params.id, category: fileCategory.Zibaiseki, vehicleKind: vehicleKind.vehicle }))
        }
      }
    }
  }, [])

  useEffect(async () => {
    if (pageFunction === editAction) {
      if (JSON.stringify(vehicleValue) !== JSON.stringify({})) {
        Object.keys(vehicleValue).map((e) => setValue(e, vehicleValue[e]));
        ('organization' in vehicleValue && vehicleValue.organization) && specialDropdown.forEach(item => {
          setValue(item, vehicleValue.organization[item])
        })
        const photoArray = []
        vehicleValue.photo1 && photoArray.push(vehicleValue.photo1)
        vehicleValue.photo2 && photoArray.push(vehicleValue.photo2)
        vehicleValue.photo3 && photoArray.push(vehicleValue.photo3)
        vehicleValue.photo4 && photoArray.push(vehicleValue.photo4)
        vehicleValue.photo5 && photoArray.push(vehicleValue.photo5)
        setSelectedFilesPhotos(prevState => { prevState = photoArray; return prevState })
        vehicleValue.document4 && selectedFilesDocument4.length === 0 && setSelectedFilesDocument4(prevState => [...prevState, vehicleValue.document4])
        const response = vehicleValue.document4 && await fetch(vehicleValue.document4, { credentials: 'include' })
        response && setContentType(response.headers.get('Content-Type'))

        const blueprintArray = []
        vehicleValue.blueprint1 && blueprintArray.push(vehicleValue.blueprint1)
        vehicleValue.blueprint2 && blueprintArray.push(vehicleValue.blueprint2)
        vehicleValue.blueprint3 && blueprintArray.push(vehicleValue.blueprint3)
        setSelectedFilesBlueprints(prevState => { prevState = blueprintArray; return prevState })
        setdataFlg(true)
      }
    }
  }, [vehicleValue])

  useEffect(async () => {
    const error = vehicleState.error
    const fileError = fileState.error
    // setIsLoading(isLoading)
    if (error && error !== noMoreErrors) {
      if (error[0] === duplication) showDialog('id_exist')
      else if (error[0] === hasCO2) showDialog('has_co2_report')
      else if (error[0] === dropdownNotExist) showDialog('dropdown_not_exist')
      else showDialog('unknown')
    }
    if (fileError && fileError !== noMoreErrors) {
      if (fileError[0] === duplication) showDialog('id_exist')
      else if (fileError[0] === hasCO2) showDialog('has_co2_report')
      else if (fileError[0] === dropdownNotExist) showDialog('dropdown_not_exist')
      else showDialog('unknown')
    }
    if (error && error === noMoreErrors && fileError && fileError === noMoreErrors) {
      if (action === editAction) {
        await dispatch(editVehicle({ exec: true, id: props.id || props.match.params.id, body: requestBody }))
        props.onBack ? props.onBack('edit') : history.push(`/viewinfo/${props.match.params.id}`)
      } else if (action === createAction) {
        await dispatch(createVehicle({ exec: true, body: requestBody }))
        props.onBack ? props.onBack('create') : history.push('/')
      } else {
        props.onBack ? props.onBack('create') : history.push('/')
      }
    }
    if (error && error === noMoreDeleteErrors) {
      props.onBack ? props.onBack('create') : history.push('/')
    }
  }, [vehicleState.error, fileState.error])

  React.useEffect(() => {
    if (fileState.list.length !== 0) {
      const vicList = fileState.list.filter((x) => { return x.category === fileCategory.Syaken })
      if (vicList.length === 1) {
        if (vicList[0].path !== null) {
          const path = vicList[0].path
          selectedFilesDocument1.length === 0 && setSelectedFilesDocument1(prevState => [...prevState, path])
        } else {
          setSelectedFilesDocument1([])
        }
        setSelectedFilesDocument2([])
      } else if (vicList.length === 2) {
        if (vicList[0].path !== null) {
          const document1Path = vicList[0].path
          selectedFilesDocument1.length === 0 && setSelectedFilesDocument1(prevState => [...prevState, document1Path])
        } else {
          setSelectedFilesDocument1([])
        }
        if (vicList[1].path !== null) {
          const document2Path = vicList[1].path
          selectedFilesDocument2.length === 0 && setSelectedFilesDocument2(prevState => [...prevState, document2Path])
        } else {
          setSelectedFilesDocument2([])
        }
      } else {
        setSelectedFilesDocument1([])
        setSelectedFilesDocument2([])
      }
      const caliList = fileState.list.filter((x) => { return x.category === fileCategory.Zibaiseki })
      if (caliList.length === 1) {
        if (caliList[0].path !== null) {
          const path = caliList[0].path
          selectedFilesDocument3.length === 0 && setSelectedFilesDocument3(prevState => [...prevState, path])
        } else {
          setSelectedFilesDocument3([])
        }
        setSelectedFilesDocument5([])
      } else if (caliList.length === 2) {
        if (caliList[0].path !== null) {
          const document3Path = caliList[0].path
          selectedFilesDocument3.length === 0 && setSelectedFilesDocument3(prevState => [...prevState, document3Path])
        } else {
          setSelectedFilesDocument3([])
        }
        if (caliList[1].path !== null) {
          const document5Path = caliList[1].path
          selectedFilesDocument5.length === 0 && setSelectedFilesDocument5(prevState => [...prevState, document5Path])
        } else {
          setSelectedFilesDocument5([])
        }
      } else {
        setSelectedFilesDocument3([])
        setSelectedFilesDocument5([])
      }
    } else {
      setSelectedFilesDocument1([])
      setSelectedFilesDocument2([])
      setSelectedFilesDocument3([])
      setSelectedFilesDocument5([])
    }
  }, [fileState.list])

  useEffect(async () => {
    setDropDownList({})
    // Get DropDown data
    await Promise.all(Object.entries(dropdownKey).map(async (value) => {
      const response = await dropDownContentListApi({ categoryId: value[1] })
      // setDropDownListを更新
      setDropDownList(prevState => ({ ...prevState, [value[1]]: response.data.rows }))
    }))
    setdrodownFlg(true)
  }, [])

  useEffect(async () => {
    // 編集時の初期表示の必須項目の動的処理
    if (dataFlg === true && drodownFlg === true) {
      requiredChange('privateBusinessUse')
      requiredChange('withLease')
      requiredChange('powerGate')
    }
  }, [dataFlg, drodownFlg])

  const calculateNextMaintenance = () => {
    let cycleNum = 360
    if (getValues('regularMaintenanceCycle')) {
      for (const i in DropDownList) {
        for (let j = 0; j < DropDownList[i].length; j++) {
          if (DropDownList[i][j].id === getValues('regularMaintenanceCycle')) {
            const name = DropDownList[i][j].name.split('ヶ月')
            cycleNum = !isNaN(Number(name[0])) ? Number(name[0]) : 360
          }
        }
      }
    }
    const lastMaintenance = new Date(getValues('lastRegularMaintenanceDate'))
    const newDate = new Date(lastMaintenance.getFullYear(), lastMaintenance.getMonth(), '01')
    // const nextMaintenance = new Date(newDate.setMonth(newDate.getMonth() + 3)) // .toISOString().substring(0, 10)
    // const gmCFormat = !isNaN(parseInt(cycleNum)) ? parseInt(cycleNum) : 3
    const nextMaintenance = new Date(newDate.setMonth(newDate.getMonth() + cycleNum))
    const options = { year: 'numeric', month: 'long' }
    const result = nextMaintenance.toLocaleDateString('ja-JP', options)
    setValue('nextRegularMaintenanceMonth', nextMaintenance.toISOString().substring(0, 10))
    return result
  }

  // const watchOrganization = (watchSingleField) => {
  //   if (pageFunction === editAction) {
  //     if (getValues('organization') && watch(watchSingleField) === '') {
  //       return true
  //     }
  //     return watch(watchSingleField)
  //   } else {
  //     return watch(specialDropdown)
  //   }
  // }
  const contains = (stringValue, charValue) => {
    return stringValue.indexOf(charValue) > -1
  }
  const allowTypeNumericOnly = (item, e) => {
    let invalidKey = null
    if (!integerNumberOnly.includes(item)) {
      invalidKey = (e.key.length === 1 && !contains(allowedChars, e.key)) || (e.key === '.' && contains(e.target.value, '.'))
    } else {
      invalidKey = (e.key.length === 1 && !contains('0123456789', e.key))
    }
    return invalidKey && e.preventDefault()
  }

  const addZeroBeforeDot = (item) => {
    const value = getValues(item).toString()
    if (getValues(item).length === 1) {
      value.charAt(0) === '.' && setValue(item, `0${getValues(item)}0`)
    } else {
      value.charAt(0) === '.' && setValue(item, `0${getValues(item)}`)
    }
  }

  const renderInput = (element) => {
    let Options = []
    switch (element.type) {
      case 'select':
        if (organizationState.list.length > 0 && specialDropdown.includes(element.value)) {
          let options = []
          try {
            switch (element.value) {
              case 'department':
                // options = [...new Set(organizationState.list
                //   .filter(item => item[element.value] !== '' && item.company === getValues('company'))
                //   .map(item => item[element.value]))]
                options = getValues('company') ? Object.keys(organizationState.restructureData[getValues('company')]) : []
                break
              case 'businessUnit':
                // options = [...new Set(organizationState.list
                //   .filter(item => item[element.value] !== '' && item.company === getValues('company') && item.department === getValues('department'))
                //   .map(item => item[element.value]))]
                options = getValues('company') &&
                  getValues('department')
                  ? Object.keys(organizationState.restructureData[getValues('company')][getValues('department')])
                  : []
                break
              case 'branchOffice':
                // options = [...new Set(organizationState.list
                //   .filter(item => item[element.value] !== '' && item.company === getValues('company') && item.department === getValues('department') && item.businessUnit === getValues('businessUnit'))
                //   .map(item => item[element.value]))]
                options = getValues('company') &&
                  getValues('department') &&
                  getValues('businessUnit')
                  ? Object.keys(organizationState.restructureData[getValues('company')][getValues('department')][getValues('businessUnit')])
                  : []
                break
              case 'branch':
                // options = [...new Set(organizationState.list
                //   .filter(item => item.company === getValues('company') && item.department === getValues('department') && item.businessUnit === getValues('businessUnit') && item.branchOffice === getValues('branchOffice'))
                //   .map(item => item[element.value]))]
                options = getValues('company') &&
                  getValues('department') &&
                  getValues('businessUnit') &&
                  getValues('branchOffice')
                  ? Object.keys(organizationState.restructureData[getValues('company')][getValues('department')][getValues('businessUnit')][getValues('branchOffice')])
                  : []
                break
              default:
                options = Object.keys(organizationState.restructureData)
                // options = [...new Set(organizationState.list.map(item => item[element.value]))]
                break
            }
          } catch (err) { }
          return (
            watch(specialDropdown) && <div className="input-group input-flex">
              <select className="input-item" {...element.required && { required: element.required }}
                {...register(element.value)}
                data-testid={element.value} value={getValues(element.value)}>
                <option value="">{i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}</option>
                {options.map(item => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
          )
        }
        if (Object.keys(DropDownList).length !== 0) {
          Object.entries(DropDownList).map(async (value) => {
            if (value[0] === element.options) {
              Options = value[1].map(item => {
                if (item.isNew === false || (item.isNew === true && item.id === vehicleValue[element.value])) {
                  return { key: item.id, value: item.name }
                }
                return null
              })
              if (element.required === false) {
                Options = [{ key: '', value: i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL') }, ...Options]
              }
            }
          })
        }

        Options = Options.filter(x => x)
        return (
          <div className="input-group input-flex">
            <RegisterMultiSelect
              className="input-item"
              marginLeft={'10px'}
              testId={element.value}
              placeholder={i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}
              options={Options}
              valueField="key"
              labelField="value"
              defaultValue={getValues(element.value)}
              element={element}
              onBlur={onBlur(element)}
            />
          </div>
        )
      case 'auto-calculated':
        return (<div className="input-group input-flex">
          <input
            type="text"
            {...register(element.value)}
            data-testid={element.value}
            hidden
            className="input-item" />
          {watch('lastRegularMaintenanceDate') && <div className="next-maintenance">{calculateNextMaintenance()}</div>}
        </div>)
      case 'date': {
        const maxDate = new Date()
        maxDate.setDate(maxDate.getDate() + element.addMaxDate)
        let max = maxDate.toLocaleDateString('sv-SE')
        if (element.maxLength !== '' && typeof element.maxLength !== 'undefined') {
          max = element.maxLength
        }
        return (<div className="input-group input-flex">
          <input
            type={element.type}
            {...register(element.value)}
            {...element.required && { required: element.required }}
            data-testid={element.value}
            min="1990-01-01"
            max={max}
            // onKeyDown={(e) => { (e.key !== 'Tab') && e.preventDefault() }}
            className="input-item" />
        </div>)
      }
      case 'textarea':
        return (
          <div className="input-group input-flex" style={{ height: '50px' }}>
            <textarea
              type={element.type}
              maxLength={element.maxLength}
              {...register(element.value)}
              data-testid={element.value}
              className="input-item">
            </textarea>
          </div>)
      case 'number':
        return (
          <div className="input-group input-flex">
            <input
              type="text"// { element.type }
              {...!integerNumberOnly.includes(element.value) && { step: 'any' }}
              onKeyDown={(e) => allowTypeNumericOnly(element.value, e)}
              maxLength={element.maxLength}
              {...register(element.value)}
              {...element.required && { required: element.required }}
              data-testid={element.value}
              className="input-item" />
          </div>)
      default:
        if (element.value === 'licensePlateNumber') {
          const licensePlateItems = []
          element.subItems && element.subItems.forEach(item => {
            licensePlateItems.push(
              <input key={item.value}
                {...item.value === 'licensePlateNumber' && { onKeyDown: (e) => allowTypeNumericOnly(item.value, e) }}
                placeholder={i18n.t(item.label)}
                type="text"
                maxLength={item.maxLength}
                {...register(item.value)}
                {...element.required && { required: element.required }}
                data-testid={item.value}
                className="input-item" />
            )
          })
          return (
            <div className="input-group input-flex">
              {licensePlateItems}
            </div>)
        }
        return (
          <div className="input-group input-flex">
            <input
              type={element.type}
              maxLength={element.maxLength}
              {...register(element.value)}
              {...element.required && { required: element.required }}
              data-testid={element.value}
              className="input-item" />
          </div>)
    }
  }

  const renderErrorText = field => {
    let errorMessage = ''
    errorMessage = errors[field] && errors[field].message
    return errorMessage ? <p className="error-text" data-testid="error-text">{errorMessage}</p> : ''
  }

  const trimExcessSpace = (item) => {
    if (item.value === 'licensePlateNumber') {
      item.subItems.map((e) => setValue(e.value, getValues(e.value).toString().trim()))
    } else {
      setValue(item.value, getValues(item.value).toString().trim())
    }
  }

  const searchRequiredChangeId = (num) => {
    let result = ''
    for (const i in DropDownList) {
      for (let j = 0; j < DropDownList[i].length; j++) {
        if (DropDownList[i][j].id === num) {
          result = DropDownList[i][j].inputType
        }
      }
    }
    return result
  }

  const searchRequiredChangeItem = (key, flg) => {
    const updatedItems = { ...localLabelName }
    Object.values(updatedItems).forEach(value => value.map(item => {
      if (item.value === key) {
        item.required = flg
      }
      return item
    }))
    clearErrors(key)
    const index = errorFields.indexOf(`${key}`)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    if (flg) {
      if (getValues(key) === '' || getValues(key) === null || typeof getValues(key) === 'undefined') {
        setError(key, {
          type: 'manual',
          message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-MANDATORY_LABEL')
        })
        setErrorFields(prevState => [...prevState, `${key}`])
      }
    }
    setLocalLabelName(updatedItems)
  }

  const addRequiredItems = (data) => {
    let count = 0
    for (let i = 0; i < localRequiredItems.length; i++) {
      if (localRequiredItems[i] === data) {
        count++
      }
    }
    if (count === 0) {
      localRequiredItems.push(data)
    }
  }

  const removeRequiredItems = (data) => {
    for (let i = 0; i < localRequiredItems.length; i++) {
      if (localRequiredItems[i] === data) {
        localRequiredItems.splice([i], 1)
        break
      }
    }
  }

  const requiredChange = (value) => {
    const requiredChangeItems = getValues(value)
    const powerGateValue = getValues('powerGate')
    const withLeaseValue = getValues('withLease')
    let inputType = ''
    switch (value) {
      case 'privateBusinessUse' :
        inputType = searchRequiredChangeId(requiredChangeItems)
        switch (inputType) {
          case '1' :
            businessUseItems.forEach(element => {
              searchRequiredChangeItem(element, false)
              removeRequiredItems(element)
            })
            searchRequiredChangeItem('powerManufacturer', false)
            removeRequiredItems('powerManufacturer')
            break
          case '2' :
            businessUseItems.forEach(element => {
              searchRequiredChangeItem(element, true)
              addRequiredItems(element)
            })
            if (searchRequiredChangeId(powerGateValue) === '1') {
              searchRequiredChangeItem('powerManufacturer', true)
              addRequiredItems('powerManufacturer')
            }
            break
        }
        break
      case 'powerGate' :
        inputType = searchRequiredChangeId(powerGateValue)
        if (searchRequiredChangeId(getValues('privateBusinessUse')) === '2') {
          if (inputType === '2') {
            searchRequiredChangeItem('powerManufacturer', false)
            removeRequiredItems('powerManufacturer')
          } else if (inputType === '1') {
            searchRequiredChangeItem('powerManufacturer', true)
            addRequiredItems('powerManufacturer')
          }
        }
        break
      case 'withLease' :
        inputType = searchRequiredChangeId(withLeaseValue)
        if (inputType === '1') {
          searchRequiredChangeItem('expensesIncludingLease', true)
          searchRequiredChangeItem('leaseEndDate', true)
          searchRequiredChangeItem('leaseFee', true)
          addRequiredItems('expensesIncludingLease')
          addRequiredItems('leaseEndDate')
          addRequiredItems('leaseFee')
        } else {
          searchRequiredChangeItem('expensesIncludingLease', false)
          searchRequiredChangeItem('leaseEndDate', false)
          searchRequiredChangeItem('leaseFee', false)
          removeRequiredItems('expensesIncludingLease')
          removeRequiredItems('leaseEndDate')
          removeRequiredItems('leaseFee')
        }
    }
  }
  const onBlur = item => () => {
    const { value, type } = item
    getValues(value) && trimExcessSpace(item)
    getValues(value) && addZeroBeforeDot(value)
    clearErrors(value)
    const index = errorFields.indexOf(`${value}`)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    if (item.required) {
      if (value === 'licensePlateNumber') {
        const foundEmpty = item.subItems.find((e) => getValues(e.value) === '')
        foundEmpty && setError(value, {
          type: 'manual',
          message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-MANDATORY_LABEL')
        })
        foundEmpty && setErrorFields(prevState => [...prevState, `${value}`])
        const licenseNum = item.subItems.find((e) => e.value === 'licensePlateNumber')
        if (licenseNum && !/^[0-9.]*$/.test(getValues(licenseNum.value))) {
          setError(value, {
            type: 'manual',
            message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-NUMERIC_LABEL')
          })
          setErrorFields(prevState => [...prevState, `${value}`])
        }
        return
      }
      if (getValues(value) === '' || getValues(value) === null || typeof getValues(value) === 'undefined') {
        setError(value, {
          type: 'manual',
          message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-MANDATORY_LABEL')
        })
        setErrorFields(prevState => [...prevState, `${value}`])
      }
    }
    if (type === 'number' && !/^[0-9.]*$/.test(getValues(value))) {
      setError(value, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-NUMERIC_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${value}`])
    }
    if (type === 'date' && getValues(value)) {
      const valueDate = new Date(getValues(value))
      const minDate = new Date('1990-01-01')
      let maxDate = new Date()
      maxDate.setDate(maxDate.getDate() + item.addMaxDate)
      if (item.maxLength !== '' && typeof item.maxLength !== 'undefined') {
        maxDate = new Date(item.maxLength)
      }
      if (minDate > valueDate || maxDate < valueDate) {
        setError(value, {
          type: 'manual',
          message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-DATE_LABEL')
        })
        setErrorFields(prevState => [...prevState, `${value}`])
      }
    }
    // 必須項目動的処理
    requiredChange(value)
  }

  const renderInputArea = (areaName, maxItemEachCol) => {
    const data = localLabelName[areaName]
    // const result = []
    const left = []
    const right = []
    const middle = []
    for (const i in data) {
      const element = data[i]
      const label = `${i18n.t(element.label)}${element.required ? '*' : ''}`
      if (areaName === 'detailedVehicleInfoTab') {
        if (i <= maxItemEachCol - 1) {
          left.push(
            <div key={i} className="input-container" onBlur={onBlur(element)}>
              <label className={element.required ? 'label-required' : ''}>{label}</label>
              <div>
                {renderInput(element)}
                {renderErrorText(element.value)}
              </div>
            </div>
          )
        } else if (i > (maxItemEachCol - 1) && i <= ((maxItemEachCol * 2))) {
          middle.push(
            <div key={i} className="input-container" onBlur={onBlur(element)}>
              <label className={element.required ? 'label-required' : ''}>{label}</label>
              <div>
                {renderInput(element)}
                {renderErrorText(element.value)}
              </div>
            </div>
          )
        } else { // if (i > ((maxItemEachCol * 2) - 1))
          right.push(
            <div key={i} className="input-container" onBlur={onBlur(element)}>
              <label className={element.required ? 'label-required' : ''}>{label}</label>
              <div>
                {renderInput(element)}
                {renderErrorText(element.value)}
              </div>
            </div>
          )
        }
      } else {
        if (i <= maxItemEachCol - 1) {
          left.push(
            <div key={i} className="input-container" onBlur={onBlur(element)}>
              <label className={element.required ? 'label-required' : ''}>{label}</label>
              <div>
                {renderInput(element)}
                {renderErrorText(element.value)}
              </div>
            </div>
          )
        } else {
          right.push(
            <div key={i} className="input-container" onBlur={onBlur(element)}>
              <label className={element.required ? 'label-required' : ''}>{label}</label>
              <div>
                {renderInput(element)}
                {renderErrorText(element.value)}
              </div>
            </div>
          )
        }
      }
    }
    return (
      <div className={middle.length === 0 ? 'split-2' : 'split-3'}>
        <div className={'left'} >{left}</div>
        <div className={'middle'} >{middle}</div>
        <div className={'right'} >{right}</div>
      </div>
    )
  }

  const showDialog = (messageCode, execPrms = {}) => {
    setShowMessage(true)
    if (messageCode === 'cancel') {
      setDialogInfo(dialogMessageList.find((e) => e.code === `${messageCode}_${pageFunction}_vehicle`))
    } else {
      messageCode === 'remove_file' && setExecParams(execPrms)
      setDialogInfo(dialogMessageList.find((e) => e.code === messageCode))
    }
  }

  const showInspectionCertificateDialog = () => {
    setShowInspectionCertificate(true)
    if (selectedFilesDocument1.length !== 0 && selectedFilesDocument2.length !== 0) {
      setShowInspectionCertificateMessage(true)
    } else {
      setShowInspectionCertificateMessage(false)
    }
  }

  const showMandatoryInsuranceDialog = () => {
    setShowMandatoryInsurance(true)
    if (selectedFilesDocument3.length !== 0 && selectedFilesDocument5.length !== 0) {
      setShowMandatoryInsuranceMessage(true)
    } else {
      setShowMandatoryInsuranceMessage(false)
    }
  }

  const handleImageChange = (files, setState, limit, tabDetail) => {
    files && files.map((file, index) => {
      return setState((prevImages) => {
        if (prevImages.length < limit) {
          return prevImages.concat([file])
        }
        return prevImages
      })
    })
    files && files.map(
      (file) => URL.revokeObjectURL(file) // avoid memory leak
    )
  }

  const handleDragOver = event => {
    event.preventDefault()
  }
  const handleOnDrop = (event, setState, tabDetail, fileTypes, docPosition = 0) => {
    // prevent the browser from opening the image
    const { limit, fileSize } = tabConstants[tabDetail]
    let sizeAsBytes = fileSize * 1024 * 1024
    event.preventDefault()
    event.stopPropagation()
    // let's grab the image file
    const files = Array.from(event.dataTransfer.files)
    const foundRightFileType = []
    let lengthOfDocs = 0
    if (tabDetail === 'registrationTab') {
      fileTypes.forEach((fileType) => {
        const foundRightFile = files.find((e) => e.type.includes(fileType))
        if (foundRightFile) foundRightFileType.push(foundRightFile)
      })
      if (foundRightFileType.length <= 0) {
        showDialog('wrong_file_type')
        return
      }
      if (docPosition === 5) {
        sizeAsBytes = 10 * 1024 * 1024
      }
    } else {
      const foundWrongFile = files.find((e) => !fileTypes.includes(e.type))
      if (foundWrongFile) {
        showDialog('wrong_file_type')
        return
      }
    }

    switch (tabDetail) {
      case 'generalTab':
        lengthOfDocs = selectedFilesPhotos.length
        break
      case 'weightSizeInfoTab':
        lengthOfDocs = selectedFilesBlueprints.length
        break
    }
    if (files.length > (limit - lengthOfDocs)) {
      showDialog('max_file_num')
      return
    }
    const foundExceedingFileSize = files.find((e) => e.size > sizeAsBytes)
    if (foundExceedingFileSize) {
      showDialog('max_file_size')
      return
    }
    handleImageChange(files, setState, limit, tabDetail, docPosition)
  }

  const removeData = (params) => {
    const { num, list, oldNum, oldList } = params
    if (oldList !== null && oldList[oldNum] !== globalThis.undefined) {
      list.splice(num, 1, oldList[oldNum])
      oldList.splice(oldNum, 1)
    } else {
      list.splice(num, 1)
    }
  }

  const renderPhoto = (num, list, tab, label = null, isDoc4 = false, oldNum = 0, oldList = null) => {
    const defaultImg = tab === 'generalTab' ? generalDefaultImg : documentImg
    let srcFile = list[num]
    let embedType = ''
    if (typeof srcFile === 'object') {
      embedType = srcFile.type
      srcFile = URL.createObjectURL(srcFile)
    }
    if (label) {
      if (embedType.includes('pdf')) {
        return (
          <>
            {list[num] && <img src={removeBtn} data-testid="remove-btn" className="remove-btn" onClick={() => showDialog('remove_file', { num, list, tab, oldNum, oldList })} />}
            {list[num] && <embed src={srcFile} style={{ pointerEvents: 'none' }} />}
            {label && <p>{i18n.t(label)}</p>}
          </>
        )
      }
      if (embedType.includes('image')) {
        return (
          <>
            {list[num] && <img src={removeBtn} data-testid="remove-btn" className="remove-btn" onClick={() => showDialog('remove_file', { num, list, tab, oldNum, oldList })} />}
            {list[num] && <img src={srcFile} alt="" />}
            {label && <p>{i18n.t(label)}</p>}
          </>
        )
      }
      if (isDoc4) {
        return (
          <>
            {list[num] && <img src={removeBtn} data-testid="remove-btn" className="remove-btn" onClick={() => showDialog('remove_file', { num, list, tab, oldNum, oldList })} />}
            {list[num] ? (contentType === 'application/pdf' ? <embed src={srcFile} style={{ pointerEvents: 'none' }} /> : <img src={srcFile} alt="" />) : <img src={defaultImg} alt="" style={{ maxWidth: '60px', filter: 'brightness(0) saturate(100%) invert(29%) sepia(27%) saturate(474%) hue-rotate(132deg) brightness(92%) contrast(98%)' }} />}
            {label && <p>{i18n.t(label)}</p>}
          </>
        )
      }
      return (
        <>
          {list[num] && <img src={removeBtn} data-testid="remove-btn" className="remove-btn" onClick={() => showDialog('remove_file', { num, list, tab, oldNum, oldList })} />}
          {list[num] ? <embed src={srcFile} style={{ pointerEvents: 'none' }} /> : <img src={defaultImg} alt="" style={{ maxWidth: '60px', filter: 'brightness(0) saturate(100%) invert(29%) sepia(27%) saturate(474%) hue-rotate(132deg) brightness(92%) contrast(98%)' }} />}
          {label && <p>{i18n.t(label)}</p>}
        </>
      )
    }
    return (
      <>
        {list[num] && <img src={removeBtn} data-testid="remove-btn" className="remove-btn" onClick={() => showDialog('remove_file', { num, list, tab, oldNum, oldList })} />}
        {list[num] ? <img src={srcFile} alt="" /> : <img className={`${tab === 'generalTab' && 'with-border'}`} src={defaultImg} alt="" style={{ maxWidth: '60px', filter: 'brightness(0) saturate(100%) invert(29%) sepia(27%) saturate(474%) hue-rotate(132deg) brightness(92%) contrast(98%)' }} />}
      </>
    )
  }

  const validation = (requiredItems) => {
    let countErrors = 0
    requiredItems.forEach(element => {
      if (getValues(element) === '' || typeof getValues(element) === 'undefined') {
        setError(element, {
          type: 'manual',
          message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-MANDATORY_LABEL')
        })
        setErrorFields(prevState => [...prevState, `${element}`])
        countErrors += 1
      }
    })
    if (countErrors > 0) {
      return false
    } else {
      return true
    }
  }

  const onClickConfirm = async (dlgInfo) => {
    switch (dlgInfo.code) {
      case 'cancel_create_vehicle':
        props.onBack ? props.onBack('create') : history.push('/')
        break
      case 'cancel_edit_vehicle':
        props.onBack ? props.onBack('edit') : history.push(`/viewinfo/${props.match.params.id}`)
        break
      case 'remove_file':
        removeData(execParams)
        break
      case 'delete_vehicle':
        confirmDelete()
        break
      default: // 'save'
        // triggerSubmit.current.click()
        onSubmit()
        break
    }
    setShowMessage(false)
  }

  const confirmDelete = async () => {
    setAction('delete')
    await dispatch(deleteVehicle({ id: props.id || props.match.params.id }))
  }

  const removeEmpty = (obj) => {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null && v !== '' && v !== undefined)
        .map(([k, v]) => [k, v !== Object(v) && v])
      // .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
    )
  }

  const onClickCancel = (dlgInfo) => {
    setShowMessage(false)
  }

  const onSubmit = async (data) => {
    if (validation(localRequiredItems)) {
      const newData = prepareRequestBody()
      let body = removeEmpty(newData)
      delete body.id
      // set attachment body
      body = await prepareAttachmentBody(body)
      body = await prepareFileInfoBody(body)
      if (pageFunction === createAction) {
        setRequestBody(body)
        pageFunction && setAction(pageFunction)
        await dispatch(createVehicle({ exec: false, body }))
        await dispatch(execFile({ exec: false, id: null, vehicleKind: vehicleKind.vehicle, body }))
      } else {
        Object.keys(vehicleValue).map((key) => {
          if (vehicleValue[key] !== '' && getValues(key) === '') {
            body[key] = null
          }
          return key
        })
        setRequestBody(body)
        pageFunction && setAction(pageFunction)
        await dispatch(editVehicle({ exec: false, id: props.id || props.match.params.id, body }))
        await dispatch(execFile({ exec: false, id: props.id || props.match.params.id, body }))
      }
    }
  }

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    // reader.onerror = error => reject(error)
  })

  const prepareRequestBody = () => {
    const findOrganization = organizationState.list && organizationState.list.find((e) => e.company === getValues('company') &&
      e.department === getValues('department') &&
      e.businessUnit === getValues('businessUnit') &&
      e.branchOffice === getValues('branchOffice') &&
      e.branch === getValues('branch'))
    setValue('organizationId', findOrganization && findOrganization.id)
    return getValues()
  }

  const prepareAttachmentBody = async (dataBody) => {
    // Photo attachments
    // get from selectedFilesPhotos
    // Document attachments
    // get from selectedFilesDocument1, selectedFilesDocument2, selectedFilesDocument3, selectedFilesDocument4
    // Blueprint attachments
    // get from selectedFilesBlueprints
    const reqAttachments = {
      photo1: selectedFilesPhotos[0] || null,
      photo2: selectedFilesPhotos[1] || null,
      photo3: selectedFilesPhotos[2] || null,
      photo4: selectedFilesPhotos[3] || null,
      photo5: selectedFilesPhotos[4] || null,
      document4: selectedFilesDocument4[0] || null,
      blueprint1: selectedFilesBlueprints[0] || null,
      blueprint2: selectedFilesBlueprints[1] || null,
      blueprint3: selectedFilesBlueprints[2] || null
    }

    // Remove item which come from API and not modify
    Object.keys(reqAttachments).map(key => {
      if (reqAttachments[key] === vehicleValue[key]) {
        delete reqAttachments[key]
        delete dataBody[key]
      }
      return key
    })

    // Set to request body
    await Promise.all(Object.keys(reqAttachments).map(async (key) => {
      if (reqAttachments[key]) {
        // Get image data if the data come from backend
        if (typeof reqAttachments[key] === 'string') {
          const response = await fetch(reqAttachments[key], {
            credentials: 'include'
          })
          const blob = await response.blob()
          reqAttachments[key] = new File([blob], key, { type: blob.type })
        }
        // Add to the request
        const b64File = await toBase64(reqAttachments[key])
        dataBody[key] = b64File
      } else {
        dataBody[key] = null
      }
    }))

    return dataBody
  }

  const prepareFileInfoBody = async (dataBody) => {
    const reqAttachments = {
      document2: selectedFilesDocument2[0] || null,
      document1: selectedFilesDocument1[0] || null,
      document5: selectedFilesDocument5[0] || null,
      document3: selectedFilesDocument3[0] || null
    }
    const data = []
    await Promise.all(Object.keys(reqAttachments).map(async (key) => {
      let category = ''
      switch (key) {
        case 'document1':
        case 'document2':
          category = '1'
          break
        case 'document3':
        case 'document5':
          category = '2'
          break
      }
      if (reqAttachments[key] !== null) {
        const fileData = await getInsertFileInfo(key, reqAttachments[key], category)
        if (fileData !== null) {
          data.push(fileData)
        }
      }
    }))
    for (let i = 0; i < fileState.list.length; i++) {
      const fileData = await getDeleteFileInfo(fileState.list[i], reqAttachments)
      if (fileData !== null) {
        data.push(fileData)
      }
    }
    dataBody.fileList = data
    return dataBody
  }

  const getInsertFileInfo = async (key, file, category) => {
    const document = fileState.list.filter((x) => { return x.path === file })
    if (document.length === 0) {
      return {
        id: null,
        path: await getb64File(file, key),
        category: category,
        fileType: 'pdf',
        execFlg: 'insert'
      }
    }
    return null
  }

  const getDeleteFileInfo = async (fileInfo, reqAttachments) => {
    let existFlg = false
    await Promise.all(Object.keys(reqAttachments).map(async (key) => {
      if (reqAttachments[key] === fileInfo.path) {
        existFlg = true
      }
    }))
    if (existFlg === false) {
      const splitPath = fileInfo.path.split('/')
      return {
        id: fileInfo.id,
        path: splitPath[splitPath.length - 1],
        category: fileInfo.category,
        fileType: 'pdf',
        execFlg: 'delete'
      }
    } else {
      return null
    }
  }

  const getb64File = async (fileData, key) => {
    if (fileData) {
      // Get image data if the data come from backend
      if (typeof fileData === 'string') {
        const response = await fetch(fileData, {
          credentials: 'include'
        })
        const blob = await response.blob()
        fileData = new File([blob], key, { type: blob.type })
      }
      // Add to the request
      const b64File = await toBase64(fileData)
      return b64File
    } else {
      return null
    }
  }

  const setInspectionCertificateFile = (okFlg) => {
    if (okFlg === true && selectedFilesDocumentModal1.length !== 0) {
      // 現行にファイルが設定されている場合、前回に移動
      if (selectedFilesDocument1.length !== 0) {
        setSelectedFilesDocument2(selectedFilesDocument1)
      }
      // 現行にダイアログで設定されたPDFファイルを設定
      setSelectedFilesDocument1(selectedFilesDocumentModal1)
    }
    // ダイアログのPDFを削除
    setSelectedFilesDocumentModal1([])
    // ダイアログを閉じる
    setShowInspectionCertificate(false)
  }

  const setMandatoryInsuranceFile = (okFlg) => {
    if (okFlg === true && selectedFilesDocumentModal2.length !== 0) {
      // 現行にファイルが設定されている場合、前回に移動
      if (selectedFilesDocument3.length !== 0) {
        setSelectedFilesDocument5(selectedFilesDocument3)
      }
      // 現行にダイアログで設定されたPDFファイルを設定
      setSelectedFilesDocument3(selectedFilesDocumentModal2)
    }
    // ダイアログのPDFを削除
    setSelectedFilesDocumentModal2([])
    // ダイアログを閉じる
    setShowMandatoryInsurance(false)
  }

  return (
    <EditVehicleWrapper>
      <Modal show={vehicleState.isLoading}><CircularProgress></CircularProgress></Modal>
      <Modal show={showInspectionCertificate}>
        <div className="modal-body">
          <div className="modal-message-content">
            {
              <>
                <p className="modal-header-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_INSPECTION-CERTIFICATE-UPLOAD_LABEL')}</p>
                <div className="drop_zone modal-dropzone" data-testid="drop-doc-1"
                  onDragOver={handleDragOver}
                  onDrop={e => handleOnDrop(e, setSelectedFilesDocumentModal1, 'registrationTab', ['application/pdf'])}
                >
                  <input type="file" multiple accept='application/pdf' hidden />
                  <div className="image">
                    <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocumentModal1, 'registrationTab', 'VEHICLE_VEHICLE-CREATE-EDIT_MODAL_LABEL')}</div>
                  </div>
                </div>
                <p className="modal-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_MODAL_MASSAGE_LABEL')}</p>
                {showInspectionCertificateMessage === true && <p className="modal-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_MODAL_INSPECTION-CERTIFICATE-FILE-CONFIRM-MASSAGE_LABEL')}</p>}
              </>
            }
          </div>
          {<div className="modal-message-button">
            <YesButton data-testid="message-yes" onClick={() => setInspectionCertificateFile(true)} />
            <NoButton data-testid="message-no" onClick={() => setInspectionCertificateFile(false)} />
          </div>}
        </div>
      </Modal>
      <Modal show={showMandatoryInsurance}>
        <div className="modal-body">
          <div className="modal-message-content">
            {
              <>
                <p className="modal-header-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_MANDATORY-INSURANCE-UPLOAD_LABEL')}</p>
                <div className="drop_zone modal-dropzone" data-testid="drop-doc-1"
                  onDragOver={handleDragOver}
                  onDrop={e => handleOnDrop(e, setSelectedFilesDocumentModal2, 'registrationTab', ['application/pdf'])}
                >
                  <input type="file" multiple accept='application/pdf' hidden />
                  <div className="image">
                    <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocumentModal2, 'registrationTab', 'VEHICLE_VEHICLE-CREATE-EDIT_MODAL_LABEL')}</div>
                  </div>
                </div>
                <p className="modal-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_MODAL_MASSAGE_LABEL')}</p>
                {showMandatoryInsuranceMessage === true && <p className="modal-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_MODAL_MANDATORY-INSURANCE-FILE-CONFIRM-MASSAGE_LABEL')}</p>}
              </>
            }
          </div>
          {<div className="modal-message-button">
            <YesButton data-testid="message-yes" onClick={() => setMandatoryInsuranceFile(true)} />
            <NoButton data-testid="message-no" onClick={() => setMandatoryInsuranceFile(false)} />
          </div>}
        </div>
      </Modal>
      <Modal show={showMessage}>
        <div className="modal-body">
          <div className="modal-message-content">{i18n.t(dialogInfo.message)}</div>
          {dialogInfo.type === 'error'
            ? <div className="modal-message-button">
              <OkButton data-testid="message-ok" onClick={() => setShowMessage(false)} />
            </div>
            : <div className="modal-message-button">
              <YesButton data-testid="message-yes" onClick={() => onClickConfirm(dialogInfo)} />
              <NoButton data-testid="message-no" onClick={() => onClickCancel(dialogInfo)} />
            </div>}
        </div>
      </Modal>
      <section className="header fix-header">
        <div className="header-title">{headerTitle}</div>
        <div className="header-actions">
          {
            (userState && ((userState.isAdmin && userState.isEdit) || userState.isEdit))
              ? pageFunction === editAction && <DeleteButton data-testid="btn-delete" onClick={() => showDialog('delete_vehicle')} />
              : ''
          }
          <SaveButton data-testid="btn-save" onClick={() => showDialog('save')} {...errorFields.length > 0 && { disabled: true }} />
          <BackButton data-testid="btn-cancel" onClick={() => showDialog('cancel')} />
        </div>
      </section>
      <FormProvider {...useFormMethods}>
        <form className="form-container" noValidate> {/* onSubmit={handleSubmit(onSubmit)} */}
          {/* <input ref={triggerSubmit} type="submit" style={{ display: 'none' }} /> */}
          <section className="tab" >
            <div className="tab-container">
              <h4>{i18n.t(tabConstants.generalTab.title)}</h4>
              {renderInputArea('generalTab', tabConstants.generalTab.maxItemEachCol)}
              <div className="wrapper">
                <div className="drop_zone general-dropzone" data-testid="drop-photo"
                  onDragOver={handleDragOver}
                  onDrop={e => handleOnDrop(e, setSelectedFilesPhotos, 'generalTab', ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'])}
                // onClick = { () => fileInput.current.click()}
                >
                  <input type="file" multiple accept="image/gif, image/jpeg, image/png, image/jpg" ref={fileInput} hidden
                  // onChange={ e => handleImageChange(e.target.files, setSelectedFilesPhotos, tabConstants.generalTab.limit) }
                  />
                  <div className="image">
                    {[...Array(tabConstants.generalTab.limit).keys()].map((e, i) => {
                      return <div key={`generalTab${i}`} className="img-item">{renderPhoto(e, selectedFilesPhotos, 'generalTab')}</div>
                    })}
                  </div>
                  <p className="dragdrop-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_VEHICLE-PHOTO-DESC_LABEL')}</p>
                </div>
              </div>
            </div>
          </section>

          <section className="tab" >
            <div className="tab-container">
              <h4>{i18n.t(tabConstants.registrationTab.title)}</h4>
              {renderInputArea('registrationTab', tabConstants.registrationTab.maxItemEachCol)}
              <div className="wrapper" style={{ flexDirection: 'column' }}>
                <div className="button-wrapper" >
                  <div className="inspection-certificate-button-wrapper">
                    <button type='button' onClick={() => showInspectionCertificateDialog()}>{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_INSPECTION-CERTIFICATE-UPLOAD_LABEL')}</button>
                  </div>
                  <div className="mandatory-insurance-button-wrapper">
                    <button type='button' onClick={() => showMandatoryInsuranceDialog()}>{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_MANDATORY-INSURANCE-UPLOAD_LABEL')}</button>
                  </div>
                </div>
                <div className="registration-wrapper">
                  <div className="drop_zone registration-dropzone" data-testid="drop-doc-1"
                    onDragOver={handleDragOver}
                  >
                    <div className="image">
                      <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocument1, 'registrationTab', 'VEHICLE_VEHICLE-CREATE-EDIT_INSPECTION-CERTIFICATE-PREVIOUS_LABEL', false, 0, selectedFilesDocument2)}</div>
                    </div>
                  </div>
                  <div className="drop_zone registration-dropzone" data-testid="drop-doc-2"
                    onDragOver={handleDragOver}
                  >
                    <div className="image">
                      <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocument2, 'registrationTab', 'VEHICLE_VEHICLE-CREATE-EDIT_INSPECTION-CERTIFICATE-TWO-TIMES-BEFORE_LABEL')}</div>
                    </div>
                  </div>
                  <div className="drop_zone registration-dropzone" data-testid="drop-doc-3"
                    onDragOver={handleDragOver}
                  >
                    <div className="image">
                      <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocument3, 'registrationTab', 'VEHICLE_VEHICLE-CREATE-EDIT_MANDATORY-INSURANCE-POLICY_LABEL', false, 0, selectedFilesDocument5)}</div>
                    </div>
                  </div>
                  <div className="drop_zone registration-dropzone" data-testid="drop-doc-5"
                    onDragOver={handleDragOver}
                  >
                    <div className="image">
                      <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocument5, 'registrationTab', 'VEHICLE_VEHICLE-CREATE-EDIT_INSPECTION-CERTIFICATE-LAST-TIME_LABEL')}</div>
                    </div>
                  </div>
                  <div className="drop_zone registration-dropzone" data-testid="drop-doc-4"
                    onDragOver={handleDragOver}
                    onDrop={e => handleOnDrop(e, setSelectedFilesDocument4, 'registrationTab', ['image/png', 'image/gif', 'image/jpeg', 'image/jpg', 'application/pdf'], 5)}
                  // onClick = { () => fileInputReg4.current.click()}
                  >
                    <input type="file" multiple accept='image/gif, image/jpeg, image/png, image/jpg, application/pdf' hidden
                    // onChange={ e => handleImageChange(e.target.files, setSelectedFilesDocument4, tabConstants.registrationTab.limit) }
                    />
                    <div className="image">
                      <div className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(0, selectedFilesDocument4, 'registrationTab', 'VEHICLE_VEHICLE-CREATE-EDIT_ATTACHED-DATA_LABEL', true)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tab" >
            <div className="tab-container">
              <h4>{i18n.t(tabConstants.weightSizeInfoTab.title)}</h4>
              {renderInputArea('weightSizeInfoTab', tabConstants.weightSizeInfoTab.maxItemEachCol)}
              <div className="wrapper">
                <div className="registration-wrapper" style={{ flexDirection: 'column' }}>
                  <div className="drop_zone registration-dropzone" data-testid="drop-blueprint"
                    onDragOver={handleDragOver}
                    onDrop={e => handleOnDrop(e, setSelectedFilesBlueprints, 'weightSizeInfoTab', ['application/pdf'])}
                  // onClick = { () => fileInput.current.click()}
                  >
                    <input type="file" multiple accept='application/pdf' ref={fileInputWS} hidden
                    // onChange={ e => handleImageChange(e.target.files, setSelectedFilesBlueprints, tabConstants.weightSizeInfoTab.limit) }
                    />
                    <div className="image">
                      {[...Array(tabConstants.weightSizeInfoTab.limit).keys()].map((e, i) => {
                        return <div key={`weightSizeInfoTab${i}`} className="img-item" style={{ height: 'auto', margin: '0' }}>{renderPhoto(e, selectedFilesBlueprints, 'weightSizeInfoTab', ' ')}</div>
                      })}
                    </div>
                    <p className="dragdrop-message">{i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_BLUE-PRINT-DESC_LABEL')}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tab remove-margin-bottom">
            <div className="tab-container">
              <h4>{i18n.t(tabConstants.detailedVehicleInfo.title)}</h4>
              {renderInputArea('detailedVehicleInfoTab', tabConstants.detailedVehicleInfo.maxItemEachCol)}
            </div>
          </section>
        </form>
      </FormProvider>
    </EditVehicleWrapper>)
}

export default EditVehicle

EditVehicle.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  state: PropTypes.string,
  id: PropTypes.string,
  onBack: PropTypes.func
}
